import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SettingsIcon from "@mui/icons-material/Settings";
import DoneIcon from "@mui/icons-material/Done";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 42,
  height: 42,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "rgb(131,72,172)",
  }),
  ...(ownerState.error && {
    backgroundColor: "rgb(252,5,74)",
  }),

  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(93,248,160) 0%, rgb(80,201,120) 50%, rgb(12,49,3) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, error } = props;
  const icons = {
    0: <DoneIcon />,
    1: <SettingsIcon />,
    2: <SettingsIcon />,
    3: <SettingsIcon />,
    4: <SettingsIcon />,
    5: <SettingsIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active, error }}
      className={props.active && !props.error && "icon-spin"}
    >
      {!props.completed && icons[String(props.icon)]}
      {props.completed && icons[String(0)]}
    </ColorlibStepIconRoot>
  );
}

export default function SnitzyStepper(props) {
  const { job, steps, alive = true } = props;
  const step = job.step;
  const type = job.type; // model score predict
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          //    "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
          "linear-gradient( 95deg,rgb(93,248,160) 0%,rgb(46,2,77) 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(12,49,3) 0%,rgb(93,248,160) 100%)",
      },
    },

    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={Number(step)}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, i) => (
          <Step key={label}>
            <StepLabel
              error={i === step && !alive}
              StepIconComponent={ColorlibStepIcon}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
// reference https://colordesigner.io/gradient-generator
