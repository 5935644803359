import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";

import MenuItemComponent from "./menuitemcomponent";

import {
  Collapse,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { useState } from "react";

export default function MenuItem(props) {
  const {
    name,
    link,
    Icon,
    items = [],
    onSelect,
    selectedKey,
    selected,
  } = props;
  const isExpandable = items && items.length > 0;
  const [open, setOpen] = useState(false);

  function handleClick() {
    setOpen(!open);
    if (!isExpandable) {
      onSelect(selectedKey);
    }
  }

  const MenuItemRoot = (
    <MenuItemComponent link={link} onClick={handleClick}>
      {/* Display an icon if any */}
      {Icon && (
        <ListItemIcon>
          <Icon
            color={selected === selectedKey && !isExpandable ? "primary" : ""}
          />
        </ListItemIcon>
      )}
      <ListItemText
        primary={name}
        inset={!Icon}
        primaryTypographyProps={
          selected === selectedKey && !isExpandable
            ? {
                color: "primary",
              }
            : {
                color: "",
              }
        }
      />
      {/* Display the expand menu if the item has children */}
      {isExpandable && !open && <IconExpandMore />}
      {isExpandable && open && <IconExpandLess />}
    </MenuItemComponent>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div">
        {items.map((item, index) => (
          <MenuItem
            {...item}
            key={index}
            selectedKey={index + selectedKey}
            onSelect={onSelect}
            selected={selected}
          />
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {name === "divider" && <Divider />}
      {name !== "divider" && (
        <li>
          {MenuItemRoot}
          {MenuItemChildren}
        </li>
      )}
    </>
  );
}
