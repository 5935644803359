//https://stackoverflow.com/questions/67553218/how-to-change-style-in-material-ui-table-pagination
//https://mui.com/components/data-grid/components/#heading-interacting-with-the-grid - useGridApi stuff
import {
  Button,
  Chip,
  Divider,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useRef, useState } from "react";
import SendToApi, { FileUploadToAPI } from "../../api/sendtoapi";

import FileUpload from "../../components/fileupload/fileupload";
import RealStateContext from "../../wss/realState/realStateContext";
import clsx from "clsx";

import FileToApi from "../../api/sendtoapi";
import StatusStepper from "../../components/stepper/statusStepper";
import FilePresentIcon from "@mui/icons-material/FilePresent";

import { DataGridPro, LicenseInfo } from "@mui/x-data-grid-pro";

const chunk_size = 1024 * 1024;

LicenseInfo.setLicenseKey(
  "ebefb899f1edf77534d25c434faaf350T1JERVI6MzY5NTIsRVhQSVJZPTE2NzUyNTI0NzUwMDAsS0VZVkVSU0lPTj0x"
);

export default function Scores() {
  const [selectedFiles, setSelectedFiles] = useState({});

  const [selectedRow, setSelectedRow] = useState({});
  const [jobId, setJobId] = useState(0);

  const [rows, setRows] = useState();
  const [parentJobId, setParentJobId] = useState(false);
  const rt = useContext(RealStateContext);

  const [submitting, setSubmitting] = useState(false);
  const [launchStatus, setLaunchStatus] = useState(0);

  const [stateLocal, setStateLocal] = useState({
    job_name: "",
    description: "",
    response_variable: "",
    t1: "",
  });

  const jobIdRef = useRef();
  jobIdRef.current = jobId;

  const selectedFilesRef = useRef();
  selectedFilesRef.current = selectedFiles;

  function handleChange(e) {
    setStateLocal((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  useEffect(() => {
    if (stateLocal.t1 === "") {
      return;
    }
    Launch();
  }, [stateLocal]);

  useEffect(() => {
    console.log(selectedFiles);
  }, [selectedFiles]);

  async function onLaunch() {
    setSubmitting(true);
    // get job id
    const newjobId = await onRegister();

    setJobId(newjobId);

    // upload files
    FileUploadToAPI(
      filestatus,
      selectedFiles["t1"].file,
      "t1",
      newjobId,
      "jobfile"
    );
  }
  function filestatus(status, type) {
    switch (status.data.state) {
      case "uploaded":
        console.log("file uploaded");
        console.log(status.data.id);
        setSelectedFiles((prev) => ({
          ...prev,
          [type]: { ...prev[type], id: status.data.id },
        }));
        switch (type) {
          case "t1":
            setLaunchStatus(2);
            Launch();
            break;
        }
      case "uploading": {
        const percent = Math.round(
          (status.data.ref / selectedFiles[type].number_of_chunks) * 100
        );
        setSelectedFiles((prev) => ({
          ...prev,
          [type]: { ...prev[type], progress: percent },
        }));
      }
    }
  }

  async function Launch() {
    setSubmitting(true);

    var values = {};
    values.fileuploads = {};
    values.fileuploads.treatment_filename = selectedFilesRef.current["t1"].id;
    values.jobid = jobIdRef.current;
    values.parent_job_id = parentJobId;
    values.job_name = stateLocal.job_name;
    values.description = stateLocal.description;
    values.response_variable = stateLocal.response_variable;
    values.type = "score";
    console.log("in launch sending the following values");
    console.log(values);
    var response = await SendToApi("scripts/launch", values);
    if (response.status === 200) {
      setLaunchStatus(3);
    } else {
    }

    setSubmitting(false);
    return false;
  }
  async function onRegister() {
    // TODO get a job id from the api
    var values = {};
    var response = await SendToApi("scripts/getjobid", values);
    if (response.status === 200) {
      setJobId(response.data.job_id);

      var initialState = { state: "launched" };
      rt.setRealTime({
        ...rt.realTime,
        [response.data.job_id]: initialState,
      });
      setLaunchStatus(1);
      return response.data.job_id;
    }
    return false;
  }

  useEffect(() => {
    const fetchCompleteData = async () => {
      const values = {};
      values.filterName = "status->>'state',type";
      values.filterValue = "completed,model";

      const response = await SendToApi("jobs/getjobs", values);

      if (response.status === 200) {
        console.log(response.data.rows);
        setRows(response.data.rows);
      }
    };
    fetchCompleteData();
  }, []);

  const columns = [
    {
      field: "job_name",
      headerName: "Name",
      width: 170,
      editable: false,
    },
    {
      field: "date",
      headerName: "Date completed",
      width: 170,
      editable: false,
      valueGetter: getDate,
      valueSetter: setDate,
    },
  ];
  function getDate(params) {
    const d = new Date(params.row._updated_at);
    return d.toUTCString().slice(0, -4); // lose the GMT
  }

  function setDate(params) {
    const [_updated_at] = params.value.toString();
    return { ...params.row, _updated_at };
  }

  function handleFileSelect(selectedFile, type, size) {
    console.log(`type ${type}`);
    console.log(`size ${size}`);
    const number_of_chunks = Math.ceil(size / chunk_size);
    console.log(`number of chunks ${number_of_chunks}`);
    setSelectedFiles((prev) => ({
      ...prev,
      [type]: {
        file: selectedFile,
        size: size,
        number_of_chunks: number_of_chunks,
        progress: 0,
      },
    }));
  }
  function CustomFooter() {
    console.log(selectedRow);

    return (
      <Box
        sx={{
          padding: "1rem",
        }}
      >
        {selectedRow.id && (
          <Chip
            label={selectedRow.job_name ? selectedRow.job_name : selectedRow.id}
            icon={<FilePresentIcon />}
          />
        )}
      </Box>
    );
  }
  return (
    <Box container sx={{ padding: "1rem" }}>
      <Paper sx={{ mb: "2rem", p: "1rem", minHeight: "200px" }}>
        <Typography color="primary" gutterBottom variant="h6">
          Launch new score
        </Typography>
        <Divider sx={{ marginBottom: "1rem" }} />
        <Box>
          {rows && (
            <>
              <Typography color="primary" gutterBottom variant="h7">
                Please select a model
              </Typography>
              <DataGridPro
                autoHeight
                rows={rows}
                columns={columns}
                // checkboxSelection
                // disableSelectionOnClick
                pagination
                pageSize={7}
                disableMultipleSelection={true}
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRowData = rows.filter((row) =>
                    selectedIDs.has(row.id.toString())
                  );
                  //console.log(ids);
                  //console.log(selectedIDs);
                  //console.log(selectedRowData);
                  setSelectedRow(selectedRowData[0]);
                  setParentJobId(selectedRowData[0].id);
                }}
                components={{
                  Footer: CustomFooter,
                }}
              />
            </>
          )}
        </Box>
        <Divider sx={{ marginBottom: "1rem" }} />
        <Box
          sx={{
            display: "flex",
            paddingBottom: "1em",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "1rem",
            }}
          >
            <TextField
              id="job_name"
              label="Model Name"
              variant="outlined"
              onChange={handleChange}
              inputProps={{
                style: {
                  padding: "18.5px 14px",
                  border: "0",
                },
              }}
            />
          </Box>
        </Box>

        <FileUpload
          show={Object.keys(selectedRow).length !== 0}
          title="Select Treatment A file"
          options="storejobfile"
          jobId={jobId}
          type="t1"
          onSelect={handleFileSelect}
        />
        <Divider sx={{ marginBottom: "1rem" }} />
        <Box
          item
          xs={12}
          sx={{
            display: "flex",
            padding: "1em",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            disabled={!selectedFiles["t1"] || !parentJobId}
            className={clsx(
              submitting && "waiting-border"
              //submitting && classes.submitting
            )}
            size="medium"
            variant="contained"
            color="primary"
            onClick={onLaunch}
          >
            Launch
          </Button>
          {selectedFiles["t1"] && (
            <StatusStepper
              steps={[
                { label: "Register" },
                { label: "Upload A", progress: selectedFiles["t1"].progress },
                { label: "Launch" },
              ]}
              step={launchStatus}
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
}
