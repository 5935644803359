import { Stepper, Step, StepLabel } from "@mui/material";
import { Box } from "@mui/system";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

export default function StatusStepper(props) {
  const { steps, step } = props;

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper alternativeLabel activeStep={parseInt(step)}>
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            {step.label.startsWith("Upload") && (
              <LinearProgressWithLabel value={step.progress} />
            )}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}
