import SendToApi from "../../api/sendtoapi";

export async function FetchAllValues(table) {
  const split = table.split(":");
  if (split.length !== 2) return;

  var values = {};
  values["table"] = split[0];
  values["fields"] = "id," + split[1];
  const response = await SendToApi("tables/gettable", values);

  if (response.status !== 200) return false;

  return response.data;
}
