// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { Box } from "@mui/system";
import { ResponsivePie } from "@nivo/pie";
import { useEffect, useState } from "react";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export default function CTResponsivePie(props) {
  const { data, height, width } = props;
  const [total, setTotal] = useState(false);

  useEffect(() => {
    var total = 0;

    data.forEach((slice) => (total += slice.value));
    setTotal(total);
  }, [data]);

  function percentage(value) {
    return Math.round((100 * value) / total) + "%";
  }

  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: "32px",
          fontWeight: 600,
        }}
      >
        {total}
      </text>
    );
  };
  return (
    <Box
      sx={{
        height: height,
        width: width,
        border: "lightgrey solid thin",
        padding: "1rem",
      }}
    >
      {total && (
        <ResponsivePie
          tooltip={({ datum: { id, value, color } }) => (
            <div
              style={{
                padding: 12,
                background: "#ffffff",
                border: `${color} thin solid`,
                borderRadius: 5,
              }}
            >
              <strong>
                {id}: {value} ({percentage(value)})
              </strong>
            </div>
          )}
          data={data}
          margin={{ right: 100, left: 100 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          layers={[
            "arcs",
            "arcLabels",
            "arcLinkLabels",
            "legends",
            CenteredMetric,
          ]}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          fill={[
            {
              match: {
                id: "ruby",
              },
              id: "dots",
            },
            {
              match: {
                id: "c",
              },
              id: "dots",
            },
            {
              match: {
                id: "go",
              },
              id: "dots",
            },
            {
              match: {
                id: "brand",
              },
              id: "dots",
            },
            {
              match: {
                id: "scala",
              },
              id: "lines",
            },
            {
              match: {
                id: "product_a",
              },
              id: "lines",
            },
            {
              match: {
                id: "elixir",
              },
              id: "lines",
            },
            {
              match: {
                id: "javascript",
              },
              id: "lines",
            },
          ]}
        />
      )}
    </Box>
  );
}
