import { Button } from "@mui/material";

export default function CTButton(props) {
  const { title, onClick } = props;
  return (
    <Button variant="contained" color="primary" onClick={onClick}>
      {title}
    </Button>
  );
}
