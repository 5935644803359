import { Routes, Route } from "react-router-dom";

import CTDAppBar from "../../appbar/appbar";
import CTDLogo from "../../logo/logo";

import Drawer from "@mui/material/Drawer";

import Menu from "../../menu/menu";
import { Box } from "@mui/system";

import GlobalStateContext from "../../globalstate/globalStateContext";

import { Typography } from "@mui/material";

// content
import Dashboard from "../../components/dashboard/dashboard";
import NewJob from "./newjob";
import Scores from "./scores";
import Reports from "../../components/reports/reports";
import ManageAccount from "../../components/manageaccounts/manageaccount";
import ProtectedRoute from "../../auth/protectedroute";
import { useContext, useEffect } from "react";
// Icons
// Icons
import StorageIcon from "@mui/icons-material/Storage";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import IconDashboard from "@mui/icons-material/Dashboard";
import FilePresentIcon from "@mui/icons-material/FilePresent";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const menuItems = [
  {
    name: "Dashboard",
    link: "dashboard",
    Icon: IconDashboard,
  },
  {
    name: "Models",
    Icon: FilePresentIcon,
    link: "newjob",
  },
  {
    name: "Scores",
    link: "scores",
    Icon: FormatListNumberedIcon,
  },
  {
    name: "Reports",
    link: "reports",
    Icon: StorageIcon,
  },

  {
    name: "divider",
  },
  {
    name: "Account",
    link: "account",
    Icon: ManageAccountsIcon,
  },
  /*{
    name: "Nested Pages",
    Icon: IconLibraryBooks,
    items: [
      {
        name: "Level 1",
        link: "/nested1",
      },
      {
        name: "Level 2",
        items: [
          {
            name: "Level 3",
          },
          {
            name: "Level 3",
          },
        ],
      },
    ],
  },*/
];
export default function Uplift() {
  const global = useContext(GlobalStateContext);

  useEffect(() => {
    global.setState((prevState) => ({
      ...prevState,
      application: "uplift",
    }));
  }, []);

  return (
    <>
      <CTDAppBar />
      <CTDLogo />
      <Drawer
        sx={{
          width: "240px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "240px",
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Menu menuItems={menuItems} />
      </Drawer>

      <Box sx={{ marginLeft: "240px", marginTop: "64px" }}>
        <Routes>
          <Route
            path="/"
            exact
            element={
              <ProtectedRoute>
                {global.state.wssConnected && <Dashboard />}
                {!global.state.wssConnected && (
                  <Box>
                    <Typography>
                      No real time service found - please contact IOTAML
                    </Typography>
                  </Box>
                )}
              </ProtectedRoute>
            }
          />
          <Route
            path="dashboard"
            exact
            element={
              <ProtectedRoute>
                {global.state.wssConnected && <Dashboard />}
              </ProtectedRoute>
            }
          />
          <Route
            path="newjob"
            exact
            element={
              <ProtectedRoute>
                <NewJob />
              </ProtectedRoute>
            }
          />
          <Route
            path="scores"
            exact
            element={
              <ProtectedRoute>
                <Scores />
              </ProtectedRoute>
            }
          />
          <Route
            path="reports"
            exact
            element={
              <ProtectedRoute>
                <Reports />
              </ProtectedRoute>
            }
          />
          <Route
            path="account"
            exact
            element={
              <ProtectedRoute>
                <ManageAccount />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Box>
    </>
  );
}
