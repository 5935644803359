import { Fragment, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Box, Paper, Typography } from "@mui/material";
import FormButton from "./types/button";
import FormCheckbox from "./types/checkbox";
import FormDateTimePicker from "./types/datetimepicker";
import FormSelect from "./types/select";
import FormTextField from "./types/textfield";
import RecaptchaField from "./types/recapture";

import FormControl from "@mui/material/FormControl";

import * as Yup from "yup";
import YupPassword from "yup-password";

YupPassword(Yup);

function createYupSchema(schema, config) {
  const { id, validationType, validations = [] } = config;
  if (id === "passwordConfirmation") {
    return schema;
  }
  if (!Yup[validationType]) {
    return schema;
  }
  let validator = Yup[validationType]();
  validations.forEach((validation) => {
    const { params, type } = validation;
    if (!validator[type]) {
      return;
    }
    validator = validator[type](...params);
  });
  schema[id] = validator;
  return schema;
}

export default function CTForm(props) {
  //const [initialValues, setInitialValues] = useState({});
  const {
    handleSubmit,
    layout,
    submitText,
    initialValuesSupplied = false,
  } = props;
  const [initialValues, setInitialValues] = useState(initialValuesSupplied);
  const [validationSchema, setValidationSchema] = useState(false);
  const [fields, setFields] = useState([]);
  const [fieldsSet, setFieldsSet] = useState(false);

  /**
   * set the fields from the layout json
   */
  useEffect(() => {
    layout.forEach((section) => {
      section.fields.forEach((field) => {
        // check for Selects
        setFields((state) => [...state, field]);
      });
    });
    setFieldsSet(true);
  }, []);

  function preHandleSubmit(values) {
    // pre run to set initial string
    var csv_fields = new Map();
    fields.forEach((field) => {
      if (field.group) {
        values[field.group] = "";
        csv_fields.set(field.group, field.group);
      }
    });
    // build the group CSV
    fields.forEach((field) => {
      if (field.group) {
        if (values[field.id]) {
          values[field.group] += `${field.id},`;
        }
      } else {
      }
    });
    // lose the trailing ,
    console.log(csv_fields);
    csv_fields.forEach((csv_field) => {
      console.log(csv_field);
      console.log(values[csv_field]);
      values[csv_field] = values[csv_field].slice(0, -1);
    });
    // strip the group individual fields
    Object.keys(values).forEach((value) => {
      const searchField = fields.find((field) => field.id === value);

      if (searchField) {
        if (searchField.hasOwnProperty("group")) {
          console.log("remove this one");
          console.log(value);
          delete values[value];
        }
      }
    });
    console.log(values);
    handleSubmit(values);
  }
  /**
   * when the fileds are set assign yup and initial values
   */
  useEffect(() => {
    if (!fieldsSet) {
      return;
    }

    const yepSchema = fields.reduce(createYupSchema, {});

    fields.forEach((field) => {
      if (field.id === "passwordConfirmation") {
        yepSchema["passwordConfirmation"] = Yup.string()
          .required("password confirmation required")
          .oneOf([Yup.ref("password"), null], "Passwords must match");
      }
    });

    setValidationSchema(Yup.object().shape(yepSchema));

    if (!initialValuesSupplied) {
      const _initialValues = {};
      fields.forEach((item) => {
        _initialValues[item.id] = item.value || "";
      });
      setInitialValues(_initialValues);
    }
  }, [fieldsSet]);

  function SortTheFields(props) {
    const { field, index, id } = props;
    if (field.type === "recaptcha") {
      return (
        <RecaptchaField
          key={index}
          name={field.id}
          label={field.label}
          value={field.value}
        />
      );
    }
    if (field.type === "Text") {
      switch (field.id) {
        case "password":
        case "passwordConfirmation":
          return (
            <FormTextField
              key={index}
              name={field.id}
              label={field.label}
              sx={{ marginBottom: "1rem", width: field.width }}
              value={field.value}
              type="password"
            />
          );
        default:
          return (
            <FormTextField
              key={index}
              name={field.id}
              label={field.label}
              value={field.value}
              sx={{ marginBottom: "1rem", width: field.width }}
            />
          );
      }
    }
    if (field.type === "DateTime") {
      return (
        <FormDateTimePicker
          name={field.id}
          label={field.label}
          value={field.value}
        />
      );
    }
    if (field.type === "Checkbox") {
      return (
        <FormCheckbox
          group={field.field}
          name={field.id}
          label={field.label}
          value={field.value}
        />
      );
    }
    if (field.type === "Select") {
      return (
        <FormSelect
          name={field.id}
          label={field.label}
          options={field.options}
          value={field.value}
          id={id}
          dbField={field}
        />
      );
    }
    return <></>;
  }

  function NonGroup(props) {
    const { section } = props;
    return (
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          padding: "1rem",
          flexDirection: "column",
        }}
      >
        {section.fields.map((field, index) => {
          return <SortTheFields field={field} id={index} key={index} />;
        })}
      </Box>
    );
  }
  function Group(props) {
    const { section, index } = props;
    return (
      <Box sx={{ marginBottom: "1rem" }}>
        <Typography variant="h8" sx={{ padding: "1rem" }} color="primary">
          {section.title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          {section.fields.map((field, index) => {
            return <SortTheFields field={field} id={index} key={index} />;
          })}
        </Box>
      </Box>
    );
  }
  return (
    <Box sx={{}}>
      <Paper>
        {validationSchema && initialValues !== false && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              preHandleSubmit(values);
            }}
          >
            <Form>
              <FormControl component="fieldset">
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    padding: "1rem",
                    flexDirection: "column",
                  }}
                >
                  {layout.map((section, index) => {
                    return (
                      <Fragment key={index}>
                        {!section.group ? (
                          <NonGroup section={section} index={index} />
                        ) : (
                          <Group section={section} index={index} />
                        )}
                      </Fragment>
                    );
                  })}
                  <FormButton>{submitText}</FormButton>
                </Box>
              </FormControl>
            </Form>
          </Formik>
        )}
      </Paper>
    </Box>
  );
}

/*
const initialState = {
  name: "fred",
  address: "",
  postcode: "",
  email: "",
};

https://codesandbox.io/s/clever-snyder-1u410?fontsize=14&file=/src/form.js:1007-1090

*/
