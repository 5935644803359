import {
  Autocomplete,
  Button,
  CircularProgress,
  Divider,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CTTreeView from "../../components/cttreeview/cttreeview";
import { styled } from "@mui/material/styles";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useEffect } from "react";

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  flexDirection: "column",
}));

export default function URLTreeView(props) {
  const {
    labels,
    data,
    handleSubmit,
    selectLabel,
    handleSelect,
    label,
    totals,
    searchOptions,
    setValue,
    expand,
  } = props; /// the select list of labels

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <Box>
      <Stack direction="row">
        <Item>
          <Typography variant="h7">Total</Typography>
          <Typography variant="h8">{totals.total}</Typography>
        </Item>
        <Item>
          <Typography variant="h7">Unique</Typography>
          <Typography variant="h8">{totals.unique}</Typography>
        </Item>
      </Stack>
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Autocomplete
          multiple
          disablePortal
          value={label}
          onChange={(e, newValue) => {
            selectLabel(newValue);
          }}
          id="combo-box-demo"
          options={labels}
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField {...params} label="Select Label" />
          )}
        />
      </Box>
      <Divider sx={{ margin: "1rem" }} />
      {searchOptions && (
        <Autocomplete
          disablePortal
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          id="combo-box-demo"
          options={searchOptions}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Search for node ..." />
          )}
        />
      )}
      <Divider sx={{ margin: "1rem" }} />
      {data && (
        <CTTreeView data={data} handleSelect={handleSelect} expand={expand} />
      )}
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          flexDirection: "column",
          padding: "1rem",
        }}
      >
        <Button
          variant="contained"
          component="label"
          color="primary"
          size="medium"
          onClick={handleSubmit}
          sx={{}}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}
/*
<Select
            labelId="select-label"
            id="select"
            value={label}
            label="Label"
            onChange={(e) => {
              saveLabel(e);
            }}
          >
            <MenuItem key={0} value="">
              no label
            </MenuItem>

            {labels.map((label, index) => {
              return (
                <MenuItem key={index + 1} value={label}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>*/
