import { useContext, useEffect, useRef, useState } from "react";
import GlobalStateContext from "../../globalstate/globalStateContext";
import RealStateContext from "./realStateContext";

//const wss = new WebSocket();

const RealStateProvider = (props) => {
  const [wss, setWss] = useState(false); // wss channel
  const [msg, setMessage] = useState(false); // messages to send to channel
  const [realTime, setRealTime] = useState({}); // store of all realtime jobs - saved in ref for wss message
  const global = useContext(GlobalStateContext);
  const stateRef = useRef();

  // set up the websocket connection
  useEffect(() => {
    setWss(new WebSocket(process.env.REACT_APP_WSS_URL));
  }, []);

  // store the realtime jobs in a ref for using in message from wss

  stateRef.current = realTime;
  // send messages

  useEffect(() => {
    if (!wss) return;
    if (!msg) return;

    var _msg = msg;
    _msg.source = "uplift";
    _msg = JSON.stringify(_msg);

    wss.send(_msg);
  }, [wss, msg]);

  function addWssChannels(jobs) {
    // create a temp job array
    var _jobs = {};

    if (!wss) {
      return;
    }
    jobs.forEach((job, i) => {
      // sort wss channels
      var channel = {};
      channel.id = job.id;
      channel.job_token = job._token;
      channel.action = "join";

      // create initial realtime array in prep of the response - this shows the job in the initial dashboard status
      const status = JSON.parse(job.status);
      // check that the job has a token and was assigned a PID

      if (job._token && job.pid) {
        // send a message to join the channel to th wss
        setMessage(channel);

        var _obj = {};
        _obj.id = job.id;
        _obj._token = job._token;
        _obj.progress = status.progress;
        _obj.step = status.step;
        _obj.state = status.state;
        _obj.pid = job.pid;
        _obj.type = job.type;
        _obj.job_name = job.job_name;
        _jobs[job.id] = _obj;
      } else {
      }
    });
    setRealTime(_jobs); /// this is one time add when screen is refreshed - jobs added in models are added to database and picked up
  }

  useEffect(() => {
    if (!wss) {
      return;
    }

    wss.onopen = () => {
      global.setState((prevState) => ({
        ...prevState,
        wssConnected: true,
      }));
    };
    wss.onclose = () => {
      global.setState((prevState) => ({
        ...prevState,
        wssConnected: false,
      }));
    };

    return () => {
      wss.close();
    };
  }, [wss]);

  useEffect(() => {
    if (!wss) return;

    // have to use REF in here
    wss.onmessage = (msg) => {
      try {
        var decoded = JSON.parse(msg.data);
      } catch (e) {
        return;
      }

      switch (decoded.action) {
        case "update":
          const _objUpdate = stateRef.current[decoded.id];
          // only update what comes from wss - dont over write job info we set up to start !
          _objUpdate.progress = decoded.progress;
          _objUpdate.step = decoded.step;
          _objUpdate.state = decoded.state;
          setRealTime((prevState) => ({
            ...prevState,
            [decoded.id]: _objUpdate,
          }));
          break;
        case "cantjoin":
          const _obj = stateRef.current[decoded.id];
          _obj.state = "refused";
          setRealTime((prevState) => ({ ...prevState, [decoded.id]: _obj }));
        case "canjoin":
          // add true to the joinedChannel for this job
          //console.log(`${decoded.id} can join`);
          break;
        default:
          break;
      }
    };
  }, [wss]);

  return (
    <RealStateContext.Provider
      value={{
        realTime: realTime,
        setRealTime: setRealTime,
        addWssChannels: addWssChannels,
      }}
    >
      {props.children}
    </RealStateContext.Provider>
  );
};

export default RealStateProvider;
