import globalStateContext from "../../globalstate/globalStateContext";
import React, { useContext, useEffect, useState } from "react";

import Typography from "@mui/material/Typography";
import ColourPicker from "../colourpicker/colourpicker";
import FileUpload from "../fileupload/fileupload";
import SendToApi from "../../api/sendtoapi";
import { FileUploadToAPI } from "../../api/sendtoapi";
import CTForm from "../../components/forms/ctform";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  useMediaQuery,
  Dialog,
  Alert,
  AlertTitle,
} from "@mui/material";

import UploadIcon from "@mui/icons-material/Upload";
import { ExpandMore, ChevronRight } from "@mui/icons-material";
import ApiDataGrid from "../apidatagrid/apidatagrid";
import { useTheme } from "@emotion/react";
import AddUserDialog from "./adduserdialog";
import layout from "./layout.json";
import CTTreeView from "../cttreeview/cttreeview";
import findNestedObject from "../../utils/RecursiveSearch";

export default function ManageAccount() {
  const global = useContext(globalStateContext);
  const theme = useTheme();
  const [addUser, setAddUser] = useState(false);
  const [logoUrl, setLogoUrl] = useState(false);
  const [settings, setSettings] = useState({});
  const labelId = `checkbox-list-label-1`;

  const fullScreen = useMediaQuery(theme.breakpoints.down("sd"));

  const handleDialog = (setValue, value) => {
    setValue(value);
  };

  useEffect(() => {
    (async () => {
      var values = {};
      values.key = "tables";
      const response = await SendToApi("accounts/getsettings", values);
      if (response.status !== 200) {
      } else {
        setSettings(JSON.parse(response.data));
      }
    })();

    return () => {};
  }, []);

  async function handleSaveSettings(values) {
    console.log(values);
    const settings = {};
    settings.values = values;
    settings.key = "tables";
    const response = await SendToApi("accounts/setsettings", settings);
    if (response.status !== 200) {
    } else {
      console.log("all good");
    }
  }

  const CurrentUserCard = () => {
    const [tfa, settfa] = useState(false);
    const [tfaSuccess, setTFASuccess] = useState(false);

    useEffect(() => {
      settfa(global.userInfo.two_factor === 1 ? true : false);
    }, []);

    function handle2faToggle() {
      setTFASuccess(false);
      settfa(!tfa);
    }

    async function handleUpdate() {
      var values = {};
      values["data"] = {};
      values["data"]["two_factor"] = tfa;
      const response = await SendToApi("users/setinfo", values);
      setTFASuccess(true);
    }

    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Button
            sx={{ marginTop: "1rem" }}
            disableRipple
            variant="contained"
            component="label"
            color="primary"
            size="medium"
            onClick={handleUpdate}
          >
            Save
          </Button>
        </Box>
        <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          <ListItem key="1" disablePadding>
            <ListItemButton role={undefined} onClick={handle2faToggle} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={tfa}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary="Two Factor Authentication Enabled"
              />
            </ListItemButton>
          </ListItem>
          {tfa && (
            <ListItem key="2" disablePadding>
              <img
                src={global.userInfo.qr_url}
                style={{
                  width: "150px",
                  height: "100%",
                  objectFit: "contain",
                }}
                alt="logo"
              />
            </ListItem>
          )}
        </List>
        {tfaSuccess && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>You have set your tfa.
          </Alert>
        )}
        <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
      </>
    );
  };

  function fileStatus(status, type) {
    switch (status.data.state) {
      case "uploaded":
        switch (type) {
          case "logo":
            console.log(status);
            break;
        }
    }
  }

  function handleSelect(file, type, size) {
    FileUploadToAPI(fileStatus, file, "logo", 0, "accountfile");
    // TODO check if its
    console.log(URL.createObjectURL(file));
    setLogoUrl(URL.createObjectURL(file));

    global.setUserInfo((prevState) => ({
      ...prevState,
      logoUrl: URL.createObjectURL(file),
      logoName: file["name"],
    }));
  }

  const BrandingCard = () => {
    return (
      <>
        <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
        <FileUpload
          title="Logo upload"
          options="accountfile"
          type="logo"
          show="true"
          jobId="0"
          avatar={false}
          onSelect={handleSelect}
        />
        {global.userInfo.logoUrl && (
          <Box sx={{}}>
            <img
              alt="logo"
              width="100px"
              height="100px"
              style={{
                marginTop: "2rem",
                objectFit: "contain",
                border: "solid thin purple",
              }}
              src={global.userInfo.logoUrl}
            />

            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {global.userInfo.logoName}
            </Typography>
          </Box>
        )}
        <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Primary Colour
        </Typography>
        <ColourPicker id="colour1" />
        <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Secondary Colour
        </Typography>
        <ColourPicker id="colour2" />
      </>
    );
  };

  const SettingsCard = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {settings && (
          <CTForm
            submitText="Save changes"
            handleSubmit={handleSaveSettings}
            layout={layout.settings}
            initialValuesSupplied={settings}
          />
        )}
      </Box>
    );
  };
  const ActionsCard = () => {
    const [dirStruct, setDirStruct] = useState(false);
    const [filename, setFileName] = useState(false);

    async function handleDedupe(_values) {
      var values = {};
      values.required_columns = "JobID,SubscriberID,URL";
      values.unique_keys = "JobID,SubscriberID,URL";
      values.table = _values.dedupetable;
      const response = await SendToApi("actions/dedupe", values);
      if (response.status === 200) {
        console.log(response);
        setDirStruct(response.data);
      }
    }

    async function handleAction(values) {
      console.log(values.action);
      const response = await SendToApi("actions/action", values);
      if (response.status === 200) {
        console.log(response);
        setDirStruct(response.data);
      }
    }

    async function handleCreate(values) {
      if (values.length === 0) return;
      const response = await SendToApi("actions/csvtodb", values);
      if (response.status === 200) {
        console.log(response);
      }
    }

    async function handleBuildClickTables() {
      var values = {};

      const response = await SendToApi("predict/buildclicktables", values); // this tells the server to make the click tables - it does not return anything
      if (response.status === 200) {
        console.log("new data retrieved");
      }
    }

    function handleSelect(node) {
      console.log(node);
      if (typeof node.file !== "undefined") {
        setFileName(node.file);
      }
    }

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleBuildClickTables}
        >
          Build click tables
        </Button>

        <CTForm
          submitText="Create"
          handleSubmit={handleCreate}
          layout={layout.actionsCSVtoDB}
        />
        <CTForm
          submitText="Dedupe"
          handleSubmit={handleDedupe}
          layout={layout.dedupe}
        />
        <CTForm
          submitText="Action"
          handleSubmit={handleAction}
          layout={layout.actions1}
        />
        {dirStruct && (
          <>
            {filename}
            <CTTreeView
              data={dirStruct}
              handleSelect={handleSelect}
              expand={[]}
            />
          </>
        )}
      </Box>
    );
  };

  function UsersCard(props) {
    const { handleDialog, setAddUser } = props;

    const columns = [
      {
        field: "username",
        headerName: "Username",
        width: 200,
        table: "users",
        editable: true,
      },
      {
        field: "permissions",
        headerName: "Permissions",
        width: 200,
        table: "users",
        editable: true,
      },
    ];

    return (
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          flexDirection: "column",
        }}
      >
        <Button
          variant="contained"
          component="label"
          color="primary"
          size="medium"
          onClick={() => handleDialog(setAddUser, true)}
          sx={{ width: "200px" }}
        >
          Add
        </Button>

        <ApiDataGrid
          initialValues={{ table: "_accounts" }}
          fetchURL="accounts/users"
          saveURL="users/setinfo"
          columns={columns}
        />

        <Divider />
      </Box>
    );
  }

  function AccordionCards(props) {
    const { children } = props;

    return (
      <>
        {React.Children.map(children, (child) => {
          return (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography component="legend" color="primary">
                  {child.props.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {React.cloneElement(child, {})}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </>
    );
  }

  return (
    <>
      <Box container sx={{ padding: "1rem" }}>
        <Paper
          sx={{
            display: "flex",
            mb: "2rem",
            p: "1rem",
            minHeight: "200px",
            gap: "1rem",
            flexDirection: "column",
          }}
        >
          <Typography color="primary" gutterBottom variant="h6">
            Manage Account
          </Typography>
          <Divider sx={{ marginBottom: "1rem" }} />
          <AccordionCards>
            <CurrentUserCard title={global.userInfo.username} />
          </AccordionCards>
          {global.userInfo.isAdmin && (
            <AccordionCards>
              <UsersCard
                title="Users"
                handleDialog={handleDialog}
                setAddUser={setAddUser}
              />
              <BrandingCard title="Branding" />
              <SettingsCard title="Settings" />
              <ActionsCard title="Actions" />
            </AccordionCards>
          )}
        </Paper>
      </Box>
      <Dialog
        fullScreen={fullScreen}
        open={addUser}
        onClose={() => handleDialog(setAddUser, false)}
        aria-labelledby="responsive-dialog-title"
      >
        <AddUserDialog handleClose={() => handleDialog(setAddUser, false)} />
      </Dialog>
    </>
  );
}
