import { DataGridPro, LicenseInfo } from "@mui/x-data-grid-pro";
LicenseInfo.setLicenseKey(
  "ebefb899f1edf77534d25c434faaf350T1JERVI6MzY5NTIsRVhQSVJZPTE2NzUyNTI0NzUwMDAsS0VZVkVSU0lPTj0x"
);

function getLabel(params) {
  const result = JSON.parse(params.row.labels);

  var returnString = "";
  if (result) {
    result.forEach((label, i) => {
      label.labels.forEach((label) => {
        returnString += `${label},`;
      });
    });
  }
  return returnString;
}

const columns = [
  {
    field: "id",
    headerName: "id",
    width: 72,
    type: "number",
    editable: false,
  },

  {
    field: "url",
    headerName: "URL",
    width: 500,
    editable: false,
  },
  {
    field: "labels",
    headerName: "labels",
    width: 220,
    valueGetter: getLabel,
    editable: false,
  },
  {
    field: "total",
    headerName: "Clicks",
    width: 100,
    editable: false,
  },
];

export default function URLTable(props) {
  const { rows, selectedRows } = props;

  return (
    <DataGridPro
      autoHeight
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      selectionModel={selectedRows}
      initialState={{
        sorting: {
          sortModel: [{ field: "id", sort: "asc" }],
        },
      }}
    />
  );
}
