import { ReadMoreRounded } from "@mui/icons-material";

const axios = require("axios").default;

// api Functions

function isset(value) {
  if (typeof value !== "undefined") {
    return true;
  }
  return false;
}

export default async function SendToApi(route, values, addToken = true) {
  // add token and username

  axios.interceptors.response.use(
    (res) => res,
    (err) => {
      return err.response;
    }
  );

  values.account_id = localStorage.getItem("account_id");

  if (addToken) {
    values.token = localStorage.getItem("token");
    values.username = localStorage.getItem("username");
  }

  if (isset(values.email) && isset(values.password)) {
    const url = process.env.REACT_APP_API_URL + route;
    const data = JSON.stringify(values);
    var basicAuth =
      "Basic " + window.btoa(values.email + ":" + values.password);
    try {
      const response = await axios({
        method: "post",
        url: url,
        data: data,
        headers: { Authorization: basicAuth },
      });
      return response;
    } catch (err) {
      console.log("*********** api error *************");
      console.log(err);
      return false;
    }
  } else {
    // no email password to worry about
    const url = process.env.REACT_APP_API_URL + route;
    const data = JSON.stringify({ ...values }); // spread incase we are sent an array .. CTform stylee
    console.log(`sending to ${url}`);
    console.log(values);
    console.log(data.length);
    if (data.length === 2) {
      // just sends []
      console.log(`************ failed to create data ***********`);
    }
    try {
      const response = await axios({
        method: "post",
        url: url,
        data: data,
      });
      return response;
    } catch (err) {
      console.log("*********** api error *************");
      console.log(err);
      return false;
    }
  }
}
export async function SendFileRequestToApi(route, values) {
  /*let _headers = {
    "Content-Type": "application/json",
  };*/

  // add token and username
  values.token = localStorage.getItem("token");
  values.username = localStorage.getItem("username");
  values.account_id = localStorage.getItem("account_id");

  var requestOptions = {
    method: "POST",
    //headers: _headers,
    body: JSON.stringify(values),
  };

  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + route,
      requestOptions
    );
    if (response.status === 200) {
      const blob = await response.blob();
      return { blob };
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

export async function SendFormToApi(route, formData) {
  // add token and username - must be in formdata

  const token = localStorage.getItem("token");
  const username = localStorage.getItem("username");
  const account_id = localStorage.getItem("account_id");

  formData.append("token", token);
  formData.append("username", username);
  formData.append("account_id", account_id);

  const url = process.env.REACT_APP_API_URL + route;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const data = formData;

  try {
    console.log("sending form");

    const response = await axios.post(url, data, config);

    return response;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export function FileUploadToAPI(
  filestatus,
  selectedFile,
  type,
  jobId,
  options
) {
  var reader = {};
  var file = selectedFile;
  var slice_size = 1000 * 1024;
  var ref = 0;
  reader = new FileReader();

  upload_file(0);

  // slice the file
  async function sendChunk(chunk, ref, percent_done) {
    var formData = new FormData();
    //console.log(`sending ${ref} to api`);

    // username and job token are allready in selectedFile
    const account_id = localStorage.getItem("account_id");
    const token = localStorage.getItem("token");
    const username = localStorage.getItem("username");

    formData.append("token", token);
    formData.append("username", username); // see job_id comment below

    formData.append("account_id", account_id);
    formData.append("chunk", chunk);
    formData.append("ref", ref);
    formData.append("type", type);
    formData.append("jobid", jobId); // send jobid not job_id as this is used by scripts and is a seperate token - this gets checked against user token instead
    formData.append("options", options);
    formData.append("filename", selectedFile.name);

    const url = process.env.REACT_APP_API_URL + "files/upload";

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    try {
      //console.log(`sending data ${ref}`);
      var response = await axios.post(url, formData, config);
      response.data.percent_done = percent_done;
      filestatus(response, type);
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async function upload_file(start) {
    var next_slice = start + slice_size + 1;
    var blob = file.slice(start, next_slice);

    reader.onloadend = async function (event) {
      if (event.target.readyState !== FileReader.DONE) {
        return;
      }

      var size_done = start + slice_size;
      var percent_done = Math.floor((size_done / file.size) * 100); // assuming it gets to api
      await sendChunk(event.target.result, ref++, percent_done);

      //console.log(`percent_done ${percent_done}`);

      if (next_slice < file.size) {
        upload_file(next_slice);
      } else {
        await sendChunk("eof", 0, 100);
      }
    };
    reader.readAsDataURL(blob);
  }
}
