import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Badge, Button, IconButton, useTheme } from "@mui/material";
// dialogs
import SettingsDialog from "./dialogs/settingsdialog";
import AlertDialog from "./dialogs/alertdialog";
// icons
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useContext, useState } from "react";
import GlobalStateContext from "./../globalstate/globalStateContext";
import Popover from "@mui/material/Popover";

const drawerWidth = 240;

export default function CTDAppBar() {
  const [settings, setSettings] = useState(false);
  const [alert, setAlert] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const global = useContext(GlobalStateContext);

  const handleDialog = (setValue, value) => {
    setValue(value);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Box sx={{}}>
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            background: "white",
          }}
        >
          <Toolbar>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                gap: "1rem",
              }}
            >
              <img
                src={`iota-${global.state.application}-logo.png`}
                style={{ width: "44px", height: "44px", objectFit: "contain" }}
                alt="logo"
              />
              <Typography variant="h6" component="div" color="primary">
                iota-{global.state.application.toUpperCase()}
              </Typography>
              <Typography
                style={{ fontSize: "10px" }}
                component="div"
                color={
                  process.env.REACT_APP_CUSTOM_NODE_ENV === "production"
                    ? "primary"
                    : "secondary"
                }
              >
                v0.39
              </Typography>
            </Box>
            <IconButton
              aria-label="delete"
              onClick={() => handleDialog(setAlert, true)}
            >
              <Badge
                badgeContent={global.userInfo.notifications}
                color="secondary"
              >
                <NotificationsIcon
                  color={global.userInfo.notifications ? "error" : "success"}
                />
              </Badge>
            </IconButton>
            <IconButton aria-label="delete" onClick={handleClick}>
              <AccountCircleIcon
                color={global.userInfo.loggedIn ? "success" : "error"}
              />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box
                sx={{
                  width: "200px",
                  height: "200px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  color="primary"
                  variant="h6"
                  sx={{ padding: "1rem" }}
                >
                  {global.userInfo.account_name}
                </Typography>
                <Typography color="primary" sx={{ padding: "1rem" }}>
                  {global.userInfo.username}
                </Typography>
                <Button variant="contained" onClick={global.logout}>
                  Logout
                </Button>
              </Box>
            </Popover>

            <IconButton
              aria-label="delete"
              onClick={() => handleDialog(setSettings, true)}
            >
              <SettingsIcon
                color={
                  !global.state.connectionError && global.state.wssConnected
                    ? "success"
                    : "error"
                }
              />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
      <Dialog
        fullScreen={fullScreen}
        open={settings}
        onClose={() => handleDialog(setSettings, false)}
        aria-labelledby="responsive-dialog-title"
      >
        <SettingsDialog handleClose={() => handleDialog(setSettings, false)} />
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={alert}
        onClose={() => handleDialog(setAlert, false)}
        aria-labelledby="responsive-dialog-title"
      >
        <AlertDialog handleClose={() => handleDialog(setAlert, false)} />
      </Dialog>
    </div>
  );
}
