//https://smartdevpreneur.com/the-ultimate-guide-to-customizing-material-ui-datagrid/

import { LicenseInfo } from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";
import { CircularProgress, Paper, Typography } from "@mui/material";
import CTResponsivePie from "../../components/nivo/pie";
import URLTreeView from "./urltreeview";
import SendToApi from "../../api/sendtoapi";
import { useEffect, useState } from "react";
import findNestedObject from "../../utils/RecursiveSearch";
import URLTable from "./urlTable";

LicenseInfo.setLicenseKey(
  "ebefb899f1edf77534d25c434faaf350T1JERVI6MzY5NTIsRVhQSVJZPTE2NzUyNTI0NzUwMDAsS0VZVkVSU0lPTj0x"
);

export default function Labeller() {
  const [data, setData] = useState({}); // data for split rows
  const [urlRows, seturlRows] = useState(); // the rows of complete url strings
  const [selectedUID, setSelectedUID] = useState(false); // the selected node
  const [labelled, setLabelled] = useState({}); // the set of labels per row sent on submit
  const [label, setLabel] = useState([]); // the current set label from the autocomplete array of labels
  const [totals, setTotals] = useState({}); // the node totals
  const [labelTotals, setLabelTotals] = useState({}); // the complied list of labels and totals
  const [pieData, setPieData] = useState(false); // piechart data
  const [selectedRows, setSelectedRows] = useState([]); // use to highlight rows selected from tree
  const [searchOptions, setSearchOptions] = useState(false); // search terms
  const [expand, setExpand] = useState([]); // used to expand tree based on search term
  const [availableLabels, setAvailableLabels] = useState(false);

  function handleNodeSearch(node) {
    if (!node) {
      setExpand([]);
      return;
    }
    console.log(`node.uid ${node.uid}`);
    console.log(data);
    // need to split on ; to find both paths
    const uids = node.uid.split(";");
    var _expand = [];
    uids.forEach((u) => {
      const id = u.split("-");
      var depth = id[1];
      const url = id[0];
      while (depth > -1) {
        const parent = `${url}-${depth--}`;
        const result = findNestedObject(data, "uid", parent, true);
        console.log(result);
        if (result) {
          _expand.push(result.uid);
        }
      }
      setExpand(_expand);
    });
  }

  /**
  // The node is selected
   */
  function handleSelect(node) {
    setLabel([]);
    console.log(node);
    const uid = node.uid;
    if (node) {
      setTotals({
        total: node.total,
        unique: node.total_unique,
        depth: node.depth,
        uid: node.uid,
      }); // assign the node variables
    }
    setSelectedUID(uid);
    var _selectedRows = [];
    uid.split(",").forEach((n) => {
      const v = n.slice(0, n.indexOf("-"));
      _selectedRows.push(v);
    });
    setSelectedRows(_selectedRows);

    if (typeof node.label === "undefined") {
      //setLabel("");
    } else {
      console.log(node.label);
      setLabel(node.label);
    }
  }

  const [fetchedData, setFetchedData] = useState(false);

  // get the available labels from the API
  useEffect(() => {
    (async () => {
      var values = {};
      values.key = "labels";
      const response = await SendToApi("accounts/getsettings", values); // this returns the info from the database
      if (response.status === 200) {
        var _labels = [];
        JSON.parse(response.data).labels.forEach((c) => {
          _labels.push(c);
        });
        setAvailableLabels(_labels);
        console.log(_labels);
      }
    })();
  }, []);

  // initial setting up of stuff
  useEffect(() => {
    (async () => {
      setFetchedData(false);
      var values = {};
      const response = await SendToApi("predict/getlabelurls", values); // this returns the info from the database
      var output = {};
      var searchTerms = [];
      if (response.status === 200) {
        seturlRows(response.data["rows"]);
        console.log("********** ROWS AS THEY COME IN FROM API ***********");
        console.log(response.data["rows"]);

        response.data["rows"].forEach((row) => {
          var labelValue = {};

          if (row.labels !== "null" && row.labels !== null) {
            const labels = JSON.parse(row.labels);
            labels.forEach((l) => {
              // need to set each depth of label per row
              labelValue[l.depth] = l.labels;
            });
          } else {
            labelValue = null;
          }

          setLabelled((prevState) => ({
            ...prevState,
            [row.id]: labelValue,
          }));

          var depth = 0;
          //   write_log($value['t']);
          const split = row["url"].split("/");
          var current = output;
          split.forEach((level) => {
            // levels are / values
            // create the new level if it does not already exist
            const total = parseInt(row["total"]);
            if (typeof current[level] === "undefined") {
              current[level] = {};
              current[level]["total"] = total;
              current[level]["total_unique"] = parseInt(row["total_unique"]);
              current[level]["uid"] = row["id"] + "-" + depth;

              // build the array of possible search terms
              const searchIndex = searchTerms.findIndex(
                (node) => node.label == level
              );

              var searchObj = {};
              if (searchIndex === -1) {
                searchObj = { label: level, uid: current[level]["uid"] };
                searchTerms.push(searchObj);
              } else {
                searchObj = {
                  label: level,
                  uid: `${current[level]["uid"]};${searchTerms[searchIndex].uid}`, // need to use a ; as uid contains ,
                };
                searchTerms[searchIndex] = searchObj;
              }

              // assign labels here - if there are multiple they are dealt by assuming they are set correctly
              if (labelValue) {
                if (labelValue[depth]) {
                  const _label = labelValue[depth];
                  // can be mulitiple so loop on labels
                  current[level]["label"] = _label;
                  _label.forEach((l) => {
                    //console.log(`${l.label} has a total of ${total}`);

                    setLabelTotals((prevState) => ({
                      ...prevState,
                      [l]: {
                        total:
                          typeof prevState[l] === "undefined"
                            ? total
                            : prevState[l].total + total,
                      },
                    }));
                  });
                }
              }

              current[level]["depth"] = depth++;
            } else {
              // we have been on this level before

              current[level]["total"] += total;
              current[level]["total_unique"] += parseInt(row["total_unique"]);
              current[level]["uid"] += "," + row["id"] + "-" + depth;

              if (labelValue) {
                if (labelValue[depth]) {
                  const _label = labelValue[depth];

                  current[level]["label"] = _label;
                  var listLabel = {};
                  listLabel[_label] = setLabelTotals((prevState) => ({
                    ...prevState,
                    [_label]: {
                      total:
                        typeof prevState[_label] === "object"
                          ? prevState[_label].total + total
                          : total,
                    },
                  }));
                }
              }
              depth++;
            }
            // move the pointer on
            current = current[level];
          });
        });

        console.log(output);
        setData(output);
        setSearchOptions(searchTerms);
        setFetchedData(true);
      }
    })();
    return () => {};
  }, []);

  // sorting pie data from data to labelTotals
  // useEffect(() => {
  //   if (!labelled) return;
  //    console.log(labelled);
  //  }, [labelled]);

  // sorting pie data from data to labelTotals
  useEffect(() => {
    if (Object.keys(data).length === 0) return;
    var pieData = [];
    Object.keys(labelTotals).forEach((key) => {
      const obj = {
        id: key,
        label: key,
        value: labelTotals[key].total,
        uids: "",
      };

      if (key !== "no label") {
        pieData.push(obj);
      }
    });

    setPieData(pieData);
  }, [labelTotals, data]);

  // submit label changes
  async function handleSubmit() {
    var values = {};

    values["labels"] = labelled;

    const response = await SendToApi("predict/submitlabels", values); // this returns the info from the database
    if (response.status === 200) {
      console.log("new labels applied");
    }
  }

  //label is selected
  function selectLabel(values) {
    // this is when the label is selected
    console.log("values");
    console.log(values);
    // check if labels have been removed - select one then the other then remove them one by one
    setLabel(values);

    const node = findNestedObject(data, "uid", selectedUID);

    if (node) {
      console.log(node);
      console.log("existing values");
      console.log(labelled);
      // set existing after you have checked
    }

    // add labels to row structure
    selectedUID.split(",").forEach((uid) => {
      const id = uid.split("-");

      const depth = id[1];
      const url = id[0];

      var _labelled = {};

      _labelled = labelled[url];

      if (!_labelled) {
        _labelled = {};
        _labelled[depth] = values;
      } else {
        _labelled[depth] = values;
      }

      setLabelled((prevState) => ({
        ...prevState,
        [url]: _labelled,
      }));
    });

    values.forEach((v) => {});

    if (node) {
      console.log(node.label);
      // loop through the selected labels
      values.forEach((value) => {
        // look for the label in the existing pieData array
        const searchIndex = pieData.findIndex((label) => label.label == value);
        // if its -1 its not currently set
        if (searchIndex === -1) {
          var obj = {
            id: value,
            label: value,
            value: node.total,
            uids: node.uid,
          };

          setPieData((prevState) => [...prevState, obj]);
        } else {
          var pieSlice = [...pieData];
          console.log(pieSlice[searchIndex].uids);
          console.log(node.uid);
          console.log(!pieSlice[searchIndex].uids.includes(node.uid));
          if (pieSlice[searchIndex].uids.includes(node.uid)) {
          } else {
            if (
              // only add if its not already set on this node or none have been set or set in labelTotals for this node
              typeof node.label === "undefined" ||
              !node.label.includes(value)
            ) {
              pieSlice[searchIndex].uids += `${node.uid}`;

              pieSlice[searchIndex].value =
                pieSlice[searchIndex].value + node.total;
              console.log(pieSlice[searchIndex].label);
              if (pieSlice[searchIndex].label !== "no label") {
                setPieData(pieSlice);
              }
            }
          }
        }
      });
    }
  }

  return (
    <Paper>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
          padding: "1rem",
          gap: "1rem",
          flexWrap: "wrap",
        }}
      >
        {!fetchedData && <CircularProgress colour="primary" />}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: "1rem",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography color="primary" gutterBottom variant="h6">
              Label Summary
            </Typography>
            {pieData && (
              <CTResponsivePie data={pieData} height="400px" width="400px" />
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <Typography color="primary" gutterBottom variant="h6">
              Tree
            </Typography>
            {availableLabels && (
              <URLTreeView
                labels={availableLabels}
                data={data}
                handleSubmit={handleSubmit}
                urlRows={urlRows}
                selectLabel={selectLabel}
                handleSelect={handleSelect}
                labelled={labelled}
                label={label}
                totals={totals}
                searchOptions={searchOptions}
                setValue={handleNodeSearch}
                expand={expand}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 2,
            }}
          >
            <Typography color="primary" gutterBottom variant="h6">
              URLs
            </Typography>
            {urlRows && <URLTable rows={urlRows} selectedRows={selectedRows} />}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
