import { useCallback, useEffect, useState, useRef } from "react";
import { DataGridPro, LicenseInfo } from "@mui/x-data-grid-pro";
import SendToApi from "../../api/sendtoapi";
import { Add, Delete, Save, Cancel } from "@mui/icons-material/";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import {
  Box,
  Chip,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";

LicenseInfo.setLicenseKey(
  "ebefb899f1edf77534d25c434faaf350T1JERVI6MzY5NTIsRVhQSVJZPTE2NzUyNTI0NzUwMDAsS0VZVkVSU0lPTj0x"
);

function CellArray(props) {
  const { value } = props;
  const decodedString = value;
  return { decodedString };
}

export default function ApiDataGrid(props) {
  const { columns, fetchURL, saveURL, initialValues } = props;
  const [rows, setRows] = useState();
  const [selectedRow, setSelectedRow] = useState(false);
  const [updated, setUpdated] = useState(false);
  const rowsRef = useRef(); // used to store the heartbeat

  rowsRef.current = rows;

  useEffect(() => {
    const fetchData = async () => {
      var fields = "";
      var values = initialValues;
      columns.forEach((column) => {
        fields += column.table + "." + column.field + ",";
        if (column.on) {
          values["on"] = column.on;
        }
        values["join_table"] = column.table;
      });
      fields = fields.slice(0, -1);
      values["fields"] = fields;

      const response = await SendToApi(fetchURL, values, true);

      if (response.status === 200) {
        // add to realtime and status
        setRows(response.data.rows);
      }
    };
    fetchData();
    return () => {};
  }, []);

  function CustomToolbar() {
    return (
      <Box
        sx={{
          display: "flex",
          direction: "row",
          gap: "1rem",
          justifyContent: "flex-end",
        }}
      >
        {selectedRow && (
          <>
            <IconButton aria-label="delete" color="primary">
              <Delete />
            </IconButton>
          </>
        )}
      </Box>
    );
  }
  function CustomFooter() {
    const key = Object.keys(selectedRow)[0];
    const label = selectedRow[key];
    return (
      <Box
        sx={{
          padding: "1rem",
          display: "flex",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        {selectedRow.id && (
          <>
            <Chip color="primary" label={label} icon={<ManageAccountsIcon />} />
            {updated && <SystemUpdateAltIcon color="success" />}
            <IconButton onClick={() => setSelectedRow(false)}>
              <Cancel color="primary" />
            </IconButton>
          </>
        )}
      </Box>
    );
  }

  // remove the updated icon
  useEffect(() => {
    if (!updated) return;
    setTimeout(() => {
      setUpdated(false);
    }, 3000);
  }, [updated]);

  const handleCellEditCommit = useCallback(async (params) => {
    var _rows = rowsRef.current;

    console.log(`id ${params.id}`);
    console.log(`field ${params.field}`);
    console.log(`value ${params.value}`);
    console.log("columns");
    console.log(columns);

    console.log("rows");
    console.log(_rows);

    const searchColumn = columns.find(
      (column) => column.field === params.field
    );

    console.log(searchColumn);
    const searchRow = _rows.find((row) => row.id === params.id);
    console.log(searchRow);
    // update the row
    searchRow[params.field] = params.value;
    setRows(_rows, console.log("finished"));

    if (params.id !== "0") {
      var values = {};
      var data = {};
      data[params.field] = params.value;
      if (searchColumn.join) {
        data["id"] = searchRow.join_id; //join_id;
      } else {
        data["id"] = params.id; //id;
      }
      values["data"] = data;
      values["table"] = searchColumn.table;
      const response = await SendToApi(saveURL, values);

      if (response.status === 200) {
        setUpdated(true);
      }
    } else {
      console.log("its a save job not edit use saveURL");
    }
  }, []);

  return (
    <>
      {rows ? (
        <DataGridPro
          autoHeight
          rows={rows}
          columns={columns}
          // checkboxSelection
          disableMultipleSelection={true}
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooter,
          }}
          onCellEditCommit={handleCellEditCommit}
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = rows.filter((row) =>
              selectedIDs.has(row.id.toString())
            );
            setUpdated(false);
            setSelectedRow(selectedRowData[0]);
          }}
        />
      ) : (
        <Typography>Sorting data ...</Typography> // todo make this so that the three dots expand and collapse
      )}
    </>
  );
}
