import { useField } from "formik";
import ReCAPTCHA from "react-google-recaptcha";

export default function RecaptchaField(props) {
  const { name } = props;
  const [field, meta, helpers] = useField(name); // you need field and meta - even though they ar enot referenced
  const { setTouched, setError, setValue } = helpers; // setValue was here
  return (
    <ReCAPTCHA
      sitekey="6LdRCnQeAAAAAOpjH1Zv76AMTbC8IE_8lZmq5_uR"
      onChange={(value) => {
        console.log(value);
        setValue(value);
      }}
    />
  );
}
