import React, { useEffect, useState } from "react";
import SendToApi, { SendFileRequestToApi } from "../api/sendtoapi";
import GlobalStateContext from "./globalStateContext";

const GlobalStateProvider = (props) => {
  const [state, setState] = useState({
    failedAuth: false,
    wssConnected: false,
    connectionError: false,
    application: "", // the name of the application uplift, predict
  });

  const [stateUplift, setStateUplift] = useState({
    validating: false,
    error: "",
    activeState: false,
    role: "",
    selectedRow: "",
  });
  const [statePredict, setStatePredict] = useState({
    validating: false,
    error: "",
    activeState: false,
    role: "",
    selectedRow: "",
  });

  const [userInfo, setUserInfo] = useState({
    token: null,
    username: null,
    loggedIn: false,
    colour1: false,
    colour2: false,
    coloursDirty: false,
    permissions: false,
    isAdmin: false,
    logoId: false,
    logoName: false,
  });

  useEffect(() => {
    //console.log("userinfo changed");
    //console.log(userInfo);
  }, [userInfo]);

  useEffect(() => {
    if (!userInfo.logoId) return;

    async function getImage(id) {
      var values = {};
      values["fileId"] = id;
      const result = await SendFileRequestToApi("files/get", values);

      if (!result.blob) {
        console.log("file has not been returned");
        console.log(result);
        return;
      }

      var url = URL.createObjectURL(result.blob);
      setUserInfo((prevState) => ({
        ...prevState,
        logoUrl: url,
      }));
    }

    getImage(userInfo.logoId);
  }, [userInfo.logoId]);

  useEffect(() => {
    if (userInfo.loggedIn) return;

    const token = localStorage.getItem("token");
    const username = localStorage.getItem("username");

    const getInfo = async () => {
      var values = {};
      values.token = userInfo.token;
      values.username = userInfo.username;
      const response = await SendToApi("users/info", values);

      if (response.status === 200) {
        //console.log("got user info");
        setUserInfo((prevState) => ({
          ...prevState,
          colour1: response.data["user_info"].colour1,
          colour2: response.data["user_info"].colour2,
          logoId: response.data["user_info"].logoId,
          logoName: response.data["user_info"].logoName,
          two_factor: response.data["user_info"].two_factor,
          permissions: response.data["user_info"].permissions.split(","),
          qr_url: response.data["user_info"].qr_url,
          account_name: response.data["user_info"].account_name,
          isAdmin: response.data["user_info"].permissions
            .split(",")
            .includes("admin")
            ? true
            : false,
        }));
      }
    };

    if (token) {
      setUserInfo((prevState) => ({
        ...prevState,
        token: token,
        username: username,
        loggedIn: true,
      }));
      getInfo();
    } else {
      //console.log("no token");
    }
  }, [userInfo.loggedIn]);

  function handleIsMember(member) {
    if (userInfo.permissions) {
      return userInfo.permissions.includes(member) ? true : false;
    }
    return false;
  }

  function handleLogout() {
    localStorage.clear();
    setUserInfo((prevState) => ({
      ...prevState,
      loggedIn: false,
      token: "",
      username: "",
      error: "",
      failedAuth: true,
    }));
  }

  function handleClearError() {
    setStateUplift((prevState) => ({ ...prevState, error: "", success: "" }));
  }
  return (
    <GlobalStateContext.Provider
      value={{
        state: state,
        setState: setState,

        stateUplift: stateUplift,
        setStateUplift: setStateUplift,

        statePredict: statePredict,
        setStatePredict: setStatePredict,

        userInfo: userInfo,
        setUserInfo: setUserInfo,

        logout: handleLogout,
        clearError: handleClearError,
        isMember: handleIsMember,
      }}
    >
      {props.children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateProvider;

//https://auth0.com/blog/complete-guide-to-react-user-authentication/
