import { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import {
  MenuItem,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Select,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/system";

export default function FileUpload(props) {
  const theme = useTheme();

  const { title, type, show, onSelect, rowLabels, onLabels } = props;

  const [selectedFile, setSelectedFile] = useState(null);
  const [file, setFile] = useState(null);
  const [fileColumns, setFileColumns] = useState(false);
  const [varLabel, setVarLabel] = useState({});

  useEffect(() => {
    if (onLabels) {
      console.log(`onLabels ${type}`);
      onLabels(type, varLabel);
    }
  }, [varLabel]);

  useEffect(() => {
    if (!fileColumns) return;
    console.log("fileColumns");
    var _labels = {};
    fileColumns.forEach((col, i) => {
      _labels[i.toString()] = 1;
    });
    _labels[0] = 0;
    setVarLabel(_labels);
  }, [fileColumns]);

  const handleChange = (event) => {
    setVarLabel((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  function onFileChange(e) {
    console.log(`onFileChange ${type}`);
    var file = e.target.files[0];
    setSelectedFile(file);
    const size = file.size;
    onSelect(file, type, size);

    // get the first line of the csv and set the file columns
    if (type !== "logo") {
      var reader = new FileReader();
      reader.onload = function (e) {
        // Print the contents of the file
        var text = e.target.result;
        var firstline = text.split(/[\r\n]+/g).shift(); // tolerate both Windows and Unix linebreaks
        setFileColumns(firstline.split(","));
      };
      reader.readAsText(file);
    }
  }

  function fileData() {
    if (selectedFile) {
      if (type !== "logo") {
        //const fileDate = new Date(selectedFile.lastModified);
        return (
          <Box>
            <Paper sx={{ padding: "1rem" }}>
              <Typography variant="h5" color="primary">
                {selectedFile.name}
              </Typography>
              <TableContainer sx={{ width: "100%" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {fileColumns &&
                        fileColumns.map((col, i) => {
                          return (
                            <TableCell key={i} align="left">
                              {col}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  </TableHead>
                  {rowLabels && (
                    <TableBody>
                      <TableRow>
                        {Object.keys(varLabel).length !== 0 &&
                          fileColumns.map((col, i) => {
                            return (
                              <TableCell key={i}>
                                {varLabel && (
                                  <Select
                                    labelId="variable-select-label"
                                    id={i.toString()}
                                    name={i.toString()}
                                    value={varLabel[i]}
                                    label="Variable"
                                    onChange={handleChange}
                                    key={i}
                                  >
                                    {rowLabels.map((label, i) => {
                                      return (
                                        <MenuItem key={i} value={i}>
                                          {label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                )}
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        );
      }
      if (type === "logo") {
        return (
          <Box sx={{}}>
            <img
              width="100px"
              height="100px"
              style={{
                marginTop: "2rem",
                objectFit: "contain",
                border: "solid thin purple",
              }}
              src={file}
              alt="file"
            />

            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {selectedFile.name}
            </Typography>
          </Box>
        );
      }
    } else {
      return (
        <div>
          <br />
        </div>
      );
    }
  }

  return (
    <Box container spacing={theme.spacing(1)} sx={{ paddingRight: "1rem" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "2rem",
        }}
      >
        <div>
          <Button
            sx={{ marginRight: "1rem" }}
            disableRipple
            disabled={!show}
            variant="contained"
            component="label"
            color="primary"
            size="medium"
          >
            {title}
            <input type="file" hidden onChange={onFileChange} />
          </Button>
        </div>
      </Box>
      <Box>{fileData()}</Box>
    </Box>
  );
}
