import {
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import TimelineIcon from "@mui/icons-material/Timeline";

import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import SendToApi from "../../api/sendtoapi";
import globalStateContext from "../../globalstate/globalStateContext";

export default function SettingsDialog(props) {
  const { handleClose } = props;

  const [apiConnected, setApiConnected] = useState(false);
  const global = useContext(globalStateContext);

  useEffect(() => {
    (async () => {
      var values = {};

      const response = await SendToApi("admin/ping", values);
      if (response.status !== 200) {
        setApiConnected(false);
      } else {
        setApiConnected(true);
      }
    })();

    return () => {};
  }, []);

  return (
    <Box>
      <DialogTitle id="responsive-dialog-title">
        {"Settings (v1.27)"}
      </DialogTitle>

      <DialogContent>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <DesktopWindowsIcon color={apiConnected ? "success" : "error"} />
            </Grid>
            <Grid item xs={6}>
              <Typography color={apiConnected ? "success" : "error"}>
                API
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <VerifiedUserIcon
                color={global.userInfo.loggedIn ? "success" : "error"}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                color={global.userInfo.loggedIn ? "success" : "error"}
              >
                Verified User
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TimelineIcon
                color={global.state.wssConnected ? "success" : "error"}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                color={global.state.wssConnected ? "success" : "error"}
              >
                WSS
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <IconButton aria-label="delete" onClick={handleClose}>
          <CancelIcon />
        </IconButton>
      </DialogActions>
    </Box>
  );
}
