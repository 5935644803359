import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

//import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { Button, Box } from "@mui/material";
import { Copyright } from "../../components/copyright";
import { Navigate } from "react-router-dom";
import { useContext, useState } from "react";
import GlobalStateContext from "../../globalstate/globalStateContext";

/*
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
*/
export default function Landing() {
  const [uplift, setUplift] = useState(false);
  const [predict, setPredict] = useState(false);

  const global = useContext(GlobalStateContext);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        margin: "3rem",
        gap: "3rem",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          alignItems: "center",
          margin: "3rem",
          gap: "3rem",
          position: "relative",
        }}
      >
        <Card sx={{ width: 200, height: 500, padding: "1rem" }}>
          <CardHeader title="UPLIFT" />
          <CardMedia
            component="img"
            sx={{ width: "200px", margins: "auto" }}
            image="iota-uplift-logo.png"
            alt="uplift"
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              Find super-responders, improve ROI. Build an optimised plan.
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <Button
              sx={{ position: "absolute", bottom: "1rem" }}
              variant="contained"
              color="primary"
              aria-label="uplift"
              onClick={() => {
                setUplift(true);
              }}
            >
              Go
            </Button>
          </CardActions>
        </Card>

        <Card sx={{ width: 200, height: 500, padding: "1rem" }}>
          <CardHeader title="PREDICT" />
          <CardMedia
            component="img"
            sx={{ width: "200px" }}
            image="iota-predict-logo.png"
            alt="predict"
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              Audience beyond rules. ML powered precision marketing that you
              control.
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <Button
              sx={{ position: "absolute", bottom: "1rem" }}
              variant="contained"
              color="primary"
              aria-label="predict"
              onClick={() => {
                setPredict(true);
              }}
            >
              Go
            </Button>
          </CardActions>
        </Card>
      </Box>
      <Button
        variant="contained"
        color="primary"
        aria-label="Load"
        onClick={() => {
          global.logout();
        }}
      >
        logout
      </Button>

      <Box>
        <img
          src="iota-logo.png"
          style={{ width: "100px", height: "100%", objectFit: "contain" }}
          alt="logo"
        />
      </Box>
      <Box>
        <Copyright />
      </Box>
      {uplift && <Navigate replace to="/uplift" />}
      {predict && <Navigate replace to="/predict" />}
    </Box>
  );
}
