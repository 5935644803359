import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import SendToApi from "./../../api/sendtoapi";
import { useState } from "react";
import { Box } from "@mui/system";

import { useEffect } from "react";

import options from "./data/options.json";
import StatusStepper from "../../components/stepper/statusStepper";
import { ExpandMore } from "@mui/icons-material";

export default function Selector() {
  const [uiClientdata, setUiClientdata] = useState();
  const [results, setResults] = useState(false);
  const [launchStatus, setLaunchStatus] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [campaigns, setCampaigns] = useState(false);
  const [clicks, setClicks] = useState(false);
  const [campaignsValue, setCampaignsValue] = useState([]);
  const [clicksValue, setClicksValue] = useState([]);

  const handlePanelChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    console.log(results);
  }, [results]);

  useEffect(() => {
    const fetchUserData = async () => {
      var values = {};

      values.name = "predict";
      const response = await SendToApi("predict/getui", values);

      if (response.status === 200) {
        var obj = JSON.parse(response.data);
        var _results = {};
        setUiClientdata(obj);
        Object.keys(obj).map((column) => {
          const parent = column;
          _results[parent] = {};
          obj[column].map((group, i) => {
            const child = Object.keys(group)[0];
            _results[parent][child] = {};
            if (group[Object.keys(group)].type === "slider") {
              _results[parent][child] = 0;
            } else {
              group[Object.keys(group)].items.map((item, i) => {
                const choice = item.label;
                _results[parent][child][choice] = 0;
              });
            }
          });
        });
      }
      //map through options here ..... and assign direct from options
      Object.keys(options).map((column) => {
        const parent = column;
        _results[parent] = {};
        options[column].map((group, i) => {
          const child = Object.keys(group)[0];
          _results[parent][child] = {};
          if (group[Object.keys(group)].type === "slider") {
            _results[parent][child] = 0;
          } else {
            group[Object.keys(group)].items.map((item, i) => {
              const choice = item.label;
              _results[parent][child][choice] = 0;
            });
          }
        });
      });

      // get the campaigns
      values = {};
      values.key = "tables";
      const settings = await SendToApi("accounts/getsettings", values);
      if (settings.status === 200) {
        const json_data = JSON.parse(settings.data);

        values = {};
        values.table = json_data.jobidtable;
        const campaigns = await SendToApi("predict/getcampaigns", values);
        console.log(campaigns);
        var _campaigns = [];
        campaigns.data.forEach((c) => {
          const obj = { label: c.emailname };
          _campaigns.push(obj);
        });
        setCampaigns(_campaigns);
        // add the campaigns to the results

        const parent = "campaigns";
        const child = "campaign";
        _results[parent] = {};
        _results[parent][child] = {};

        Object.keys(_campaigns).map((i) => {
          _results[parent][child][_campaigns[i].label] = 0;
        });
        _results[parent]["response_variable"] = {};
        _results[parent]["response_variable"]["opens"] = 0;
        _results[parent]["response_variable"]["clicks"] = 0;
      }

      // get the campaigns
      values = {};
      values.key = "tables";

      var values = {};
      values.key = "labels";
      const responseClicks = await SendToApi("accounts/getsettings", values); // this returns the info from the database
      if (responseClicks.status === 200) {
        var _clicks = [];
        JSON.parse(responseClicks.data).labels.forEach((c) => {
          const obj = { label: c };
          _clicks.push(obj);
        });
        setClicks(_clicks);
      }

      // add the campaigns to the results

      const parent = "clicks";
      const child = "clicks";
      _results[parent] = {};
      _results[parent][child] = {};

      Object.keys(_clicks).map((i) => {
        _results[parent][child][_clicks[i].label] = 0;
      });

      _results["details"] = {};
      _results["details"]["model_name"] = {};
      _results["details"]["description"] = {};

      setResults(_results);
    };

    if (!results) fetchUserData();
  }, []);

  function handleCampaignChange(event, values) {
    var _results = results;
    const parent = "campaigns";
    const child = "campaign";
    values.forEach((value) => {
      _results[parent][child][value.label] = 1;
    });
    console.log(_results);
    setCampaignsValue(values);
    setResults({ ..._results });
  }

  function handleClicksChange(event, values) {
    var _results = results;
    const parent = "clicks";
    const child = "clicks";
    values.forEach((value) => {
      _results[parent][child][value.label] = 1;
    });
    console.log(_results);
    setClicksValue(values);
    setResults({ ..._results });
  }

  const handleChange = (parent, child, choice, event) => {
    var _results = results;
    if (event.target.name === "slider") {
      _results[parent][child][choice] = event.target.value;
    } else {
      _results[parent][child][choice] = !results[parent][child][choice];
    }
    setResults({ ..._results });
  };

  const handleRadioChange = (parent, child, event) => {
    var _results = results;
    _results[parent][child] = event.target.value;
    setResults({ ..._results });
  };

  async function Register() {
    var values = {};
    var response = await SendToApi("scripts/getjobid", values);
    if (response.status === 200) {
      setLaunchStatus(1);
      return response.data.job_id;
    }
    return false;
  }

  async function handleRun() {
    var output = {};

    setTimeout(() => {
      setLaunchStatus(2);
    }, 1000);
    setTimeout(() => {
      setLaunchStatus(3);
    }, 3000);

    console.log(results);

    Object.keys(results).forEach((group) => {
      console.log(`${group} **********************`);
      output[group] = {};
      Object.keys(results[group]).forEach((item) => {
        output[group][item] = {};

        switch (group) {
          case "details":
            output[group][item] = results[group][item];
            break;

          case "options":
            output[group][item] = results[group][item];
            break;

          case "customers":
            output[group][item] = results[group][item];
            break;
          case "clicks":
          case "products":
            Object.keys(results[group][item]).forEach((choice) => {
              if (results[group][item][choice]) {
                console.log(`${choice} is set`);
                output[group][item][choice] = 1;
              }
            });
            break;
          case "campaigns":
            console.log(item);
            if (item === "response_variable") {
              output[group][item] = results[group][item];
            } else {
              Object.keys(results[group][item]).forEach((choice) => {
                if (results[group][item][choice]) {
                  console.log(`${choice} is set`);
                  output[group][item][choice] = 1;
                }
              });
            }
            break;
        }
      });
    });
    console.log("output");
    console.log(output);

    const jobId = await Register();

    var values = {};
    values.jobid = jobId;
    values.data_info = JSON.stringify(output);
    values.type = "predict";

    var response = await SendToApi("scripts/launch", values);
    if (response.status === 200) {
    } else {
    }
  }

  function Run() {
    return (
      <Paper>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
            padding: "1rem",
          }}
        >
          <Button
            variant="contained"
            component="label"
            color="primary"
            size="medium"
            onClick={handleRun}
            sx={{}}
          >
            Run
          </Button>
        </Box>
      </Paper>
    );
  }

  function Campaigns(props) {
    const { handlePanelChange, expanded, campaigns, handleRadioChange } = props;

    return (
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          flexWrap: "wrap",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Paper>
          <Accordion
            expanded={expanded === "campaigns"}
            onChange={handlePanelChange("campaigns")}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography
                sx={{ textTransform: "capitalize" }}
                color="primary"
                variant="h7"
              >
                Campaigns
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {campaigns && (
                <Autocomplete
                  multiple
                  disablePortal
                  value={campaignsValue}
                  onChange={(event, newValue) => {
                    handleCampaignChange(event, newValue);
                  }}
                  id="combo-box-demo"
                  options={campaigns}
                  sx={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Campaigns" />
                  )}
                />
              )}
              <FormControl sx={{ marginTop: "1rem" }}>
                <FormLabel id="demo-radio-buttons-group-label">
                  Response Variable
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="open"
                  value={results["campaigns"]["response_variable"]}
                  name="radio-buttons-group"
                  onChange={(e) =>
                    handleRadioChange("campaigns", "response_variable", e)
                  }
                >
                  <FormControlLabel
                    value="open"
                    control={<Radio />}
                    label="Open"
                  />
                  <FormControlLabel
                    value="clicks"
                    control={<Radio />}
                    label="Clicks"
                  />
                </RadioGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
        </Paper>
      </Box>
    );
  }
  function Clicks(props) {
    const { handlePanelChange, expanded, campaigns, handleRadioChange } = props;

    return (
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          flexWrap: "wrap",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Paper>
          <Accordion
            expanded={expanded === "clicks"}
            onChange={handlePanelChange("clicks")}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography
                sx={{ textTransform: "capitalize" }}
                color="primary"
                variant="h7"
              >
                Clicks
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {clicks && (
                <Autocomplete
                  multiple
                  disablePortal
                  value={clicksValue}
                  onChange={(event, newValue) => {
                    handleClicksChange(event, newValue);
                  }}
                  id="combo-box-demo"
                  options={clicks}
                  sx={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Clicks" />
                  )}
                />
              )}
            </AccordionDetails>
          </Accordion>
        </Paper>
      </Box>
    );
  }
  function SelectorDisplay(props) {
    const {
      results,
      expanded,
      setResults,
      handleRadioChange,
      handlePanelChange,
      handleChange,
      group,
      title,
      parent,
      uiClientdata,
    } = props;

    return (
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          flexWrap: "wrap",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {Object.keys(uiClientdata).map((column, i) => {
          return (
            <Paper key={i}>
              <Accordion
                expanded={expanded === column}
                onChange={handlePanelChange(column)}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography
                    sx={{ textTransform: "capitalize" }}
                    color="primary"
                    variant="h7"
                  >
                    {column}
                  </Typography>
                </AccordionSummary>

                {uiClientdata[column].map((group, i) => {
                  return (
                    <AccordionDetails key={i}>
                      <Typography component="legend" color="primary">
                        {Object.keys(group)}
                      </Typography>
                      <Group
                        results={results}
                        handleRadioChange={handleRadioChange}
                        handleChange={handleChange}
                        group={group[Object.keys(group)]}
                        title={Object.keys(group)[0]}
                        parent={column}
                      />
                    </AccordionDetails>
                  );
                })}
              </Accordion>
            </Paper>
          );
        })}
      </Box>
    );
  }

  function Form(props) {
    const { results, setResults, handlePanelChange, expanded } = props;

    function handleChange(e) {
      console.log(e.target.id);
      console.log(e.target.value);

      var _results = results;
      _results["details"][e.target.id] = e.target.value;
      setResults(_results);
    }

    return (
      <Accordion
        expanded={expanded === "details"}
        onChange={handlePanelChange("details")}
      >
        <AccordionSummary>
          <Typography
            sx={{ textTransform: "capitalize" }}
            color="primary"
            variant="h7"
          >
            Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            sx={{ m: "1rem" }}
            id="model_name"
            label="Model Name"
            variant="outlined"
            onChange={handleChange}
            inputProps={{
              style: {
                padding: "18.5px 14px",
                border: "0",
              },
            }}
          />
          <TextField
            sx={{ m: "1rem" }}
            id="description"
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            onChange={handleChange}
            rows={4}
            inputProps={{
              style: {
                padding: "18.5px 14px",
                border: "0",
              },
            }}
          />
        </AccordionDetails>
      </Accordion>
    );
  }

  function Options(props) {
    const {
      results,
      setResults,
      handleChange,
      handleRadioChange,
      handlePanelChange,
      expanded,
    } = props;

    return (
      <Paper>
        <Accordion
          expanded={expanded === "form"}
          onChange={handlePanelChange("form")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography
              sx={{ textTransform: "capitalize" }}
              color="primary"
              variant="h7"
            >
              Options
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Sliders
              results={results}
              setResults={setResults}
              handleRadioChange={handleRadioChange}
              handleChange={handleChange}
            />
            <Typography color="secondary" variant="subtitle2">
              Note. Just applies to clicks and campaigns
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Paper>
    );
  }
  function Sliders(props) {
    const { results, setResults, handleRadioChange, handleChange } = props;
    return (
      <Box
        sx={{
          //   border: "red solid thick",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "1rem",
          padding: "1rem",
        }}
      >
        <FormItem
          results={results}
          setResults={setResults}
          handleRadioChange={handleRadioChange}
          handleChange={handleChange}
          title="Frequency"
          group={{ type: "slider" }}
        />
        <FormItem
          results={results}
          setResults={setResults}
          handleRadioChange={handleRadioChange}
          handleChange={handleChange}
          title="Recency"
          group={{ type: "slider" }}
        />
      </Box>
    );
  }
  function FormItem(props) {
    const {
      results,
      setResults,
      handleChange,
      handleRadioChange,
      title,
      group,
      show = true,
    } = props;
    return (
      <Box sx={{ width: "100%", padding: "1rem" }}>
        {show && <FormLabel>{title}</FormLabel>}
        <Group
          results={results}
          setResults={setResults}
          handleRadioChange={handleRadioChange}
          handleChange={handleChange}
          group={group}
          title={title}
          parent="options"
        />
      </Box>
    );
  }

  function Group(props) {
    const {
      results,
      setResults,
      handleRadioChange,
      handleChange,
      group,
      title,
      parent,
    } = props;
    switch (group.type) {
      case "radio":
        return (
          <GroupRadio
            results={results}
            handleRadioChange={handleRadioChange}
            group={group}
            title={title}
            parent={parent}
          />
        );

      case "checkbox":
        return (
          <GroupCheckbox
            results={results}
            handleChange={handleChange}
            group={group}
            title={title}
            parent={parent}
          />
        );

      case "slider":
        return (
          <GroupSlider
            results={results}
            setResults={setResults}
            title={title}
            parent={parent}
          />
        );

      default:
        return <></>;
    }
  }
  function GroupRadio(props) {
    const { results, handleRadioChange, group, title, parent } = props;
    const child = title;

    return (
      <FormControl component="fieldset">
        <RadioGroup
          aria-label={group}
          name="radio-buttons-group"
          value={results[parent][child]}
          onChange={(e) => handleRadioChange(parent, child, e)}
        >
          {group.items.map((item, i) => {
            return (
              <FormControlLabel
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                labelPlacement="start"
                value={item.label}
                control={<Radio />}
                label={item.label}
                key={i}
                name="radio"
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  }

  function GroupCheckbox(props) {
    const { results, group, title, parent, handleChange } = props;
    const child = title;

    return (
      <FormControl component="fieldset">
        <FormGroup aria-label={group} name="checkbox-group">
          {group.items.map((item, i) => {
            var choice = item.label;
            //console.log(String(i + 1));
            return (
              <FormControlLabel
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                labelPlacement="start"
                value={item.label}
                control={
                  <Checkbox
                    onChange={(e) => handleChange(parent, child, choice, e)}
                    checked={
                      results[parent][child][choice] !== true ? false : true
                    }
                  />
                }
                label={item.label}
                key={i}
                name="checkbox"
              />
            );
          })}
        </FormGroup>
      </FormControl>
    );
  }
  const marksFrequency = [
    {
      value: 0,
      label: "Everything",
    },
    {
      value: 1,
      label: "2+",
    },
  ];
  const marksRecency = [
    {
      value: 0,
      label: "Everything",
    },
    {
      value: 1,
      label: "Last 12m",
    },
  ];

  function GroupSlider(props) {
    const { results, setResults, title, parent } = props;
    const child = title;

    const [value, setValue] = useState(results[parent][child]);

    const handleSliderChange = (event, newValue) => {
      if (typeof newValue === "number") {
        setValue(newValue);
      }
      var _results = results;
      _results[parent][child] = event.target.value;
      setResults(_results);
    };

    return (
      <Slider
        sx={{ width: "100%" }}
        value={Object.is(NaN, value) ? 0 : value}
        name="slider"
        aria-label="slider"
        onChange={handleSliderChange}
        min={0}
        max={1}
        marks={title === "Frequency" ? marksFrequency : marksRecency}
      />
    );
  }
  return (
    <Box container sx={{ padding: "1rem" }}>
      <Paper sx={{ mb: "2rem", p: "1rem", minHeight: "200px" }}>
        <Typography color="primary" gutterBottom variant="h6">
          Selector
        </Typography>
        <Divider sx={{ marginBottom: "1rem" }} />
        {uiClientdata && results && (
          <Box sx={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
            <Campaigns
              expanded={expanded}
              handlePanelChange={handlePanelChange}
              campaigns={campaigns}
              handleRadioChange={handleRadioChange}
            />
            <Clicks
              expanded={expanded}
              handlePanelChange={handlePanelChange}
              campaigns={clicks}
              handleRadioChange={handleClicksChange}
            />
            <SelectorDisplay
              results={results}
              setResults={setResults}
              handlePanelChange={handlePanelChange}
              handleRadioChange={handleRadioChange}
              handleChange={handleChange}
              uiClientdata={uiClientdata}
              expanded={expanded}
            />
            <Options
              results={results}
              setResults={setResults}
              handlePanelChange={handlePanelChange}
              expanded={expanded}
            />
            <Form
              results={results}
              setResults={setResults}
              handlePanelChange={handlePanelChange}
              expanded={expanded}
            />
            <StatusStepper
              steps={[
                { label: "Register" },
                { label: "Store" },
                { label: "Launch" },
              ]}
              step={launchStatus}
            />
            <Run />
          </Box>
        )}
      </Paper>
    </Box>
  );
}
