import MenuItem from "./menuitem.js";
import { Divider, List } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

export default function Menu(props) {
  const { menuItems } = props;
  const [selected, setSelected] = useState(0);

  function handleClick(index) {
    setSelected(index);
  }

  return (
    <div>
      <Box sx={{ width: "241px", height: "64px" }} />
      <Divider />
      <List>
        {menuItems.map((item, index) => (
          <MenuItem
            {...item}
            key={index}
            selectedKey={index}
            onSelect={handleClick}
            selected={selected}
          />
        ))}
      </List>
    </div>
  );
}
