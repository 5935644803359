import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box } from "@mui/system";

export default function AlertDialog(props) {
  const { handleClose } = props;
  return (
    <Box>
      <DialogTitle id="responsive-dialog-title">{"Alerts"}</DialogTitle>
      <DialogContent>
        <DialogContentText>Alerts here.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <IconButton aria-label="delete" onClick={handleClose}>
          <CancelIcon />
        </IconButton>
      </DialogActions>
    </Box>
  );
}
