import { Divider, IconButton, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import SendToApi, { SendFileRequestToApi } from "../../api/sendtoapi";

import { DataGridPro, LicenseInfo } from "@mui/x-data-grid-pro";
import DownloadIcon from "@mui/icons-material/Download";

LicenseInfo.setLicenseKey(
  "ebefb899f1edf77534d25c434faaf350T1JERVI6MzY5NTIsRVhQSVJZPTE2NzUyNTI0NzUwMDAsS0VZVkVSU0lPTj0x"
);

async function onClick(type, params) {
  console.log(params.row);

  const path = process.env.REACT_APP_USERFILES;
  var filename;
  switch (type) {
    case "report":
      filename = process.env.REACT_APP_REPORT_FILE;
      break;
    case "score":
      filename = process.env.REACT_APP_SCORE_FILE;
      break;
    default:
      filename = process.env.REACT_APP_REPORT_FILE;
      break;
  }
  const account_id = localStorage.getItem("account_id");
  const user_id = localStorage.getItem("user_id");
  const fullFileName = `${path}${account_id}/${user_id}/${params.row.id}/${filename}`;

  console.log(fullFileName);

  var values = {};
  values["filename"] = fullFileName;

  const result = await SendFileRequestToApi("files/content", values);

  console.log(result);
  if (!result.blob) {
    console.log("file has not been returned");
    return;
  }

  var url = URL.createObjectURL(result.blob);
  console.log(url);

  const link = document.createElement("a");
  // create a blobURI pointing to our Blob
  link.href = url;
  link.download = `${params.row.id}-${filename}`;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
}

function getDate(params) {
  const d = new Date(params.row._updated_at);
  return d.toUTCString().slice(0, -4); // lose the GMT
}

function setDate(params) {
  const [_updated_at] = params.value.toString();
  return { ...params.row, _updated_at };
}

const columns = [
  {
    field: "job_name",
    headerName: "Name",
    width: 170,
    editable: false,
  },
  {
    field: "type",
    headerName: "Type",
    width: 70,
    editable: false,
  },
  {
    field: "date",
    headerName: "Date completed",
    width: 170,
    editable: false,
    valueGetter: getDate,
    valueSetter: setDate,
  },
  {
    field: "report",
    headerName: "Report",
    width: 70,
    editable: false,
    renderCell: (params) => {
      return (
        <IconButton
          onClick={() => onClick("report", params)}
          color="primary"
          aria-label="download report"
        >
          <DownloadIcon />
        </IconButton>
      );
    },
  },
  {
    field: "score",
    headerName: "Score",
    width: 70,
    editable: false,
    renderCell: (params) => {
      return (
        <IconButton
          onClick={() => onClick("score", params)}
          color="primary"
          aria-label="download score"
        >
          <DownloadIcon />
        </IconButton>
      );
    },
  },
];

export default function Reports() {
  const [rows, setRows] = useState();

  useEffect(() => {
    const fetchCompleteData = async () => {
      const values = {};
      values.filterName = "status->>'state'";
      values.filterValue = "completed";

      const response = await SendToApi("jobs/getjobs", values);

      console.log(response);
      if (response.status !== 200) {
        console.log("no 200");
        console.log(response.data);
        return;
      }
      if (!response.data.rows) {
        console.log("no rows");
        return;
      }
      console.log(response.data.rows);
      setRows(response.data.rows);
    };
    fetchCompleteData();
  }, []);

  return (
    <Box container sx={{ padding: "1rem" }}>
      <Paper sx={{ mb: "2rem", p: "1rem", minHeight: "200px" }}>
        <Typography color="primary" gutterBottom variant="h6">
          Reports
        </Typography>
        <Divider sx={{ marginBottom: "1rem" }} />
        <Box>
          {rows && (
            <DataGridPro
              autoHeight
              rows={rows}
              columns={columns}
              // checkboxSelection
              disableSelectionOnClick
              disableMultipleSelection={true}
              /* onSelectionModelChange={(ids) => {
                 const selectedIDs = new Set(ids);
                 const selectedRowData = rows.filter((row) =>
                   selectedIDs.has(row.id.toString())
                 );
                 console.log(ids);
                 console.log(selectedIDs);
                 console.log(selectedRowData);
              }} */
            />
          )}
        </Box>
        <Divider sx={{ marginBottom: "1rem" }} />
      </Paper>
    </Box>
  );
}
