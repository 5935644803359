import { Autocomplete, FormHelperText, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useField } from "formik";
import { useEffect, useState } from "react";
import { FetchAllValues } from "../../database/database";

const FormSelect = ({ dbField, name, label, id, ...props }) => {
  const [field, meta, helpers] = useField(name); // can pass 'props' into useField also, if 'props' contains a name attribute
  const { setTouched, setError, setValue } = helpers; // setValue was here
  const [options, setOptions] = useState(false);
  const [autoValue, setAutoValue] = useState(false);

  const handleChange = (option) => {
    if (option) {
      setValue(option.value);
    } else {
      setValue(options[0]);
    }
    setAutoValue(option);
    setTouched(true);
    setError("");
  };

  useEffect(() => {
    Fetch();
  }, []);

  useEffect(() => {
    if (!options) return;
    setAutoValue(options[0]);
  }, [options]);

  async function Fetch() {
    const data = await FetchAllValues(dbField.options);
    var selectData = [];
    data.rows.forEach((d, index) => {
      var obj = {};
      obj.value = d.id;
      obj.label = d.name;
      selectData[index] = obj;
    });
    selectData.unshift({ value: "0", label: "Please Select a " + label });
    setOptions(selectData);
  }

  return (
    <>
      <Box sx={{ marginBottom: "2rem" }}>
        {options && (
          <>
            <Autocomplete
              getOptionLabel={(option) => option.label || ""}
              value={autoValue}
              onChange={(event, newValue) => {
                handleChange(newValue);
              }}
              id="controllable-states-demo"
              options={options}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label={label} />}
            />
            <FormHelperText sx={{ color: "red" }}>
              {meta.touched && !meta.value && meta.error}
            </FormHelperText>
          </>
        )}
      </Box>
    </>
  );
};

export default FormSelect;
/*
<pre>{JSON.stringify(props, null, 2)}</pre>
      <pre>{meta.value}</pre>
      <pre>{field.value}</pre>
      */
// ref https://codesandbox.io/s/6x9405rlqk?file=/example.js // for styles and placeholder
