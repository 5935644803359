import React from "react";
import { Button } from "@mui/material";
import { useFormikContext } from "formik";

const FormButton = ({ children }) => {
  const { submitForm } = useFormikContext();

  return (
    <Button
      sx={{ width: "200px" }}
      variant="contained"
      color="primary"
      onClick={submitForm}
    >
      {children}
    </Button>
  );
};

export default FormButton;
