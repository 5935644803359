import {
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import FileUpload from "../../components/fileupload/fileupload";
import SendToApi from "../../api/sendtoapi";
import clsx from "clsx";
import { Box } from "@mui/system";

import RealStateContext from "../../wss/realState/realStateContext";
import { FileUploadToAPI } from "../../api/sendtoapi";
import StatusStepper from "../../components/stepper/statusStepper";
import { Navigate } from "react-router-dom";

const chunk_size = 1024 * 1024;

export default function NewJob() {
  // State
  const [selectedFiles, setSelectedFiles] = useState({});
  const [selectedFileLabels, setSelectedFileLabels] = useState({});
  const [jobId, setJobId] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [launchStatus, setLaunchStatus] = useState(0);
  const [launchComplete, setLaunchComplete] = useState(false);
  const [stateLocal, setStateLocal] = useState({
    t1: "",
    t2: "",
    c1: "",
    job_name: "",
    description: "",
    response_variable: "",
  });

  useEffect(() => {
    console.log("in new job");
  }, []);
  // Context
  const rt = useContext(RealStateContext);

  const jobIdRef = useRef();
  jobIdRef.current = jobId;

  const selectedFilesRef = useRef();
  selectedFilesRef.current = selectedFiles;

  const rowLabels = ["Outcome", "Feature", "Id", "Ignore"];

  function filestatus(status, type) {
    switch (status.data.state) {
      case "uploaded":
        console.log("file uploaded");
        console.log(status.data.id);
        setSelectedFiles((prev) => ({
          ...prev,
          [type]: { ...prev[type], id: status.data.id },
        }));

        switch (type) {
          case "t1":
            setLaunchStatus(2);
            FileUploadToAPI(
              filestatus,
              selectedFiles["t2"].file,
              "t2",
              jobIdRef.current,
              "jobfile"
            );
            break;
          case "t2":
            setLaunchStatus(3);
            Launch();
            break;
        }
      case "uploading": {
        const percent = status.data.percent_done;
        //        console.log(`uploading ${percent} done`);
        setSelectedFiles((prev) => ({
          ...prev,
          [type]: { ...prev[type], progress: percent },
        }));
      }
    }
  }

  // Async Functions

  async function onLaunch() {
    console.log("onlaunch");
    setSubmitting(true);
    // get job id

    // get the job id
    const newjobId = await onRegister();
    setJobId(newjobId);

    // upload files
    FileUploadToAPI(
      filestatus,
      selectedFiles["t1"].file,
      "t1",
      newjobId,
      "jobfile"
    );
  }

  async function Launch() {
    console.log("launch");
    var values = {};
    values.fileuploads = {};
    values.fileuploads.treatment_filename = selectedFilesRef.current["t1"].id;
    values.fileuploads.control_filename = selectedFilesRef.current["t2"].id;
    values.job_name = stateLocal.job_name;
    values.description = stateLocal.description;

    values.jobid = jobIdRef.current;

    console.log(values);
    var _labels = selectedFileLabels;
    for (const key in _labels["t1"]) {
      //console.log(`the ${key}: ${_labels["t1"][key]}`);
      if (_labels["t1"][key] !== "") {
        _labels["t1"][key] = rowLabels[_labels["t1"][key]];
      }
    }

    values.data_info = JSON.stringify(_labels);
    //console.log("_labels");
    //console.log(_labels);
    values.type = "model";

    values.job_name = stateLocal.job_name;
    values.description = stateLocal.description;
    values.response_variable = stateLocal.response_variable;
    //console.log("launching script");
    //console.log(values);
    var response = await SendToApi("scripts/launch", values);
    if (response.status === 200) {
      setLaunchStatus(4);
      setLaunchComplete(true);
    } else {
    }

    setSubmitting(false);
    return false;
  }

  async function onRegister() {
    // TODO get a job id from the api
    console.log("register");
    var values = {};
    var response = await SendToApi("scripts/getjobid", values);
    if (response.status === 200) {
      setJobId(response.data.job_id);

      var initialState = { state: "launched" };
      rt.setRealTime({
        ...rt.realTime,
        [response.data.job_id]: initialState,
      });

      setLaunchStatus(launchStatus + 1);
      return response.data.job_id;
    }
    return false;
  }
  // Functions
  function handleChange(e) {
    console.log(e.target.id);
    setStateLocal((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  function handleSelect(selectedFile, type, size) {
    console.log(`type ${type}`);
    console.log(`size ${size}`);
    const number_of_chunks = Math.ceil(size / chunk_size);
    console.log(`number of chunks ${number_of_chunks}`);
    setSelectedFiles((prev) => ({
      ...prev,
      [type]: {
        file: selectedFile,
        size: size,
        number_of_chunks: number_of_chunks,
        progress: 0,
      },
    }));
  }

  function handleLabels(type, labels) {
    console.log("handleLabels");
    setSelectedFileLabels((prev) => ({ ...prev, [type]: labels }));
  }

  // return
  return (
    <Box container sx={{ padding: "1rem" }}>
      <Paper sx={{ mb: "2rem", p: "1rem", minHeight: "200px" }}>
        {launchComplete && <Navigate replace to="/uplift/dashboard" />}
        <Typography color="primary" gutterBottom variant="h6">
          Launch new model
        </Typography>
        {!submitting && (
          <>
            <Divider sx={{ marginBottom: "1rem" }} />
            <Grid item xs={12}>
              <FileUpload
                title="Select Treatment A file"
                show={true}
                jobId={jobId}
                type="t1"
                onSelect={handleSelect}
                rowLabels={rowLabels}
                onLabels={handleLabels}
              />
            </Grid>

            <Divider sx={{ marginBottom: "1rem" }} />

            <FileUpload
              title="Select Treatment B file"
              show={true}
              jobId={jobId}
              type="t2"
              onSelect={handleSelect}
            />
          </>
        )}
        <Divider />

        <Box sx={{ marginTop: "1rem" }}>
          <TextField
            value={stateLocal.job_name}
            sx={{ m: "1rem" }}
            id="job_name"
            label="Model Name"
            variant="outlined"
            onChange={handleChange}
            inputProps={{
              style: {
                padding: "18.5px 14px",
                border: "0",
              },
            }}
          />

          <TextField
            value={stateLocal.description}
            sx={{ m: "1rem" }}
            id="description"
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            onChange={handleChange}
            rows={4}
            inputProps={{
              style: {
                padding: "18.5px 14px",
                border: "0",
              },
            }}
          />
        </Box>

        <Box
          item
          xs={12}
          sx={{
            display: "flex",
            padding: "1em",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            disabled={!selectedFiles["t1"] || !selectedFiles["t2"]}
            className={clsx(
              submitting && "waiting-border"
              //submitting && classes.submitting
            )}
            size="medium"
            variant="contained"
            color="primary"
            onClick={onLaunch}
          >
            Launch
          </Button>
          {selectedFiles["t1"] && selectedFiles["t2"] && (
            <StatusStepper
              steps={[
                { label: "Register" },
                { label: "Upload A", progress: selectedFiles["t1"].progress },
                { label: "Upload B", progress: selectedFiles["t2"].progress },
                { label: "Launch" },
              ]}
              step={launchStatus}
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
}
