import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import StateProvider from "./globalstate/globalStateProvider";
import RealStateProvider from "./wss/realState/realStateProvider";
import { createTheme } from "@mui/material";
import { purple, blue, green } from "@mui/material/colors";
import { ThemeProvider } from "@mui/system";

const theme = createTheme({
  palette: {
    primary: purple,
    secondary: blue,
    success: green,
  },
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <StateProvider>
      <RealStateProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </RealStateProvider>
    </StateProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
