import { useContext, useEffect, useState, useRef } from "react";

import RealStateContext from "../../wss/realState/realStateContext";

import { Alert, Avatar, Divider, Paper, Typography } from "@mui/material";

import SendToApi from "../../api/sendtoapi";
import { Box } from "@mui/system";

import SnitzyStepper from "../stepper/SnitzyStepper";
import RealTimeTable from "../../wss/components/RealTimeTable";

import CTButton from "../../pages/uplift/ctbutton";

//icons
import FilePresentIcon from "@mui/icons-material/FilePresent";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import BallotIcon from "@mui/icons-material/Ballot";

import { purple } from "@mui/material/colors";

export default function Dashboard() {
  const [statusRows, setStatusRows] = useState();
  const [heartbeat, setHeartbeat] = useState({});
  const stateRef = useRef(); // used to store the heartbeat

  stateRef.current = heartbeat;

  const rt = useContext(RealStateContext);

  async function setJobStatus(job, status) {
    var values = {};
    values.job_id = job.id;
    values.token = job._token;
    //    values.token = job_token;
    values.status = {};
    values.status.state = status;
    const response = await SendToApi("scripts/status", values, false); // false means dont send user token
    switch (response.status) {
      case 200:
        var _jobs = statusRows;
        _jobs = _jobs.filter((value, i) => {
          return value.id != job.id;
        });
        setStatusRows(_jobs);
        break;
      case 408:
        var _jobs = statusRows;
        const _jobIndex = _jobs.findIndex((value, i) => {
          return value.id === job.id;
        });
        _jobs[_jobIndex]["error"] =
          "can not remove - incorrect token - please contact IOTAML";
        console.log("after error");
        console.log(_jobs);
        setStatusRows([..._jobs]);
        break;
    }

    console.log("after filter");
    console.log(_jobs);
  }

  useEffect(() => {
    //console.log("realtime has changed");
    //console.log(rt.realTime);
    if (!rt.realTime) {
      console.log("realtime is empty");
      return;
    }

    Object.keys(rt.realTime).map(async (keyName, i) => {
      if (rt.realTime[keyName].state === "completed") {
      }
    });
  }, [rt.realTime]);

  useEffect(() => {}, [heartbeat]);

  useEffect(() => {
    if (!rt.realTime) return;

    function heartBeat() {
      var numOfKeys = Object.keys(rt.realTime).length;
      //console.log(`heartbeat on the ${numOfKeys} realtime jobs`);
      if (numOfKeys === 0) {
        return false;
      }
      Object.keys(rt.realTime).forEach(async (keyName, i) => {
        if (rt.realTime[keyName].state === "launched") {
          //console.log(`calling api to check heart beat of ${keyName}`);
          var values = {};
          values.pid = rt.realTime[keyName].pid;
          values.job_id = rt.realTime[keyName].id;
          values.token = rt.realTime[keyName]._token;
          var response = await SendToApi("scripts/heartbeat", values, false);
          if (response.status === 200) {
            if (response.data === 1) {
              setHeartbeat((prevState) => ({
                ...prevState,
                [keyName]: true,
              }));
            } else {
              numOfKeys--;
              setHeartbeat((prevState) => ({
                ...prevState,
                [keyName]: false,
              }));
            }
          }
        } else {
          //  console.log(`${keyName} has a state of ${rt.realTime[keyName].state}`);
        }
      });
      return true;
    }

    const id = setInterval(() => {
      if (!heartBeat()) {
        clearInterval(id);
      }
    }, 3000);
    return () => {
      //console.log(`clearing timer ${id}`);
      clearInterval(id);
    };
  }, [rt.realTime]);

  // Fetch the jobs from the database - any job that is launched and has a token
  useEffect(() => {
    //console.log("fetch jobs from database");
    const fetchStatusData = async () => {
      const values = {};
      values.filterName = "_token,status->>'state'";
      values.filterValue = "NOT NULL,launched";

      const response = await SendToApi("jobs/getjobs", values);
      if (response.status === 200) {
        // add to realtime and status
        rt.addWssChannels(response.data.rows);
        setStatusRows(response.data.rows);
      }
    };
    fetchStatusData();
  }, []);

  function JobTitle(props) {
    const { job } = props;
    var icon;
    switch (job.type) {
      case "model":
        icon = <FilePresentIcon />;
        break;
      case "score":
        icon = <FormatListNumberedIcon />;
        break;
      case "predict":
        icon = <BallotIcon />;
        break;
    }
    return (
      <Box
        sx={{
          display: "flex",
          direction: "row",
          gap: "1rem",
          alignItems: "center",
          paddingBottom: "1rem",
        }}
      >
        <Avatar sx={{ bgcolor: purple[500] }}>{icon}</Avatar>
        <Typography color={purple[500]}>{job.type}</Typography>
        <Typography color={purple[500]}>
          {job.job_name ? job.job_name : job.id}
        </Typography>
      </Box>
    );
  }
  function RealTimeLive(props) {
    const { job, i } = props;
    console.log(job);
    //console.log(rt.realTime);
    //console.log(rt.realTime[job.state]);
    if (rt.realTime[job.id] && rt.realTime[job.id].state !== "refused") {
      return (
        <Box key={i}>
          <JobTitle job={job} />
          <Box
            sx={{
              display: "flex",
              direction: "row",
              gap: "1rem",
              alignItems: "flex-start",
              paddingBottom: "1rem",
            }}
          >
            <SnitzyStepper
              steps={
                job.type === "model"
                  ? [
                      "Preparation",
                      "Features",
                      "Models",
                      "Optimisation",
                      "Results",
                    ]
                  : job.type === "score"
                  ? [
                      "Validate data",
                      "Load model",
                      "Score data",
                      "Generate report",
                    ]
                  : []
              }
              alive={heartbeat[job.id]}
              job={rt.realTime[job.id]}
            />
            {heartbeat[job.id] === false && (
              <CTButton
                title="remove"
                onClick={() => setJobStatus(job, "stopped")}
              />
            )}
          </Box>
          <Divider />
        </Box>
      );
    } else {
      // set the job to corrupt
      return (
        <>
          <JobTitle job={job} />
          <Box key={i} sx={{ display: "flex", direction: "row", gap: "1rem" }}>
            <Typography>
              This job does not have any processes running
            </Typography>
            <CTButton
              title="remove"
              onClick={() => setJobStatus(job, "removed")}
            >
              Remove
            </CTButton>
            <Typography>
              {job.error && <Alert severity="error">{job.error}</Alert>}
            </Typography>
          </Box>
        </>
      );
    }
  }

  return (
    <Box container sx={{ padding: "1rem" }}>
      <Paper sx={{ mb: "2rem", p: "1rem", minHeight: "200px" }}>
        <Typography color="primary" gutterBottom variant="h6">
          Live Status
          {Object.keys(rt.realTime).length === 0 && (
            <Typography color="#777">
              No real time models currently being processed
            </Typography>
          )}
        </Typography>
        <Divider sx={{ marginBottom: "1rem" }} />
        {statusRows && // we only return launched jobs
          statusRows.map((job, i) => <RealTimeLive job={job} i={i} key={i} />)}
      </Paper>
    </Box>
  );
}
