import Box from "@mui/material/Box";
import { useContext } from "react";
import GlobalStateContext from "../globalstate/globalStateContext";

export default function CTDLogo() {
  const global = useContext(GlobalStateContext);

  return (
    <Box
      sx={{
        position: "fixed",
        top: "10px",
        left: "16px",
        height: "44px",
        width: "208px",
        zIndex: "10000",
      }}
    >
      {global.userInfo.logoUrl && (
        <a href="/landing">
          <img
            src={global.userInfo.logoUrl}
            style={{ width: "208px", height: "44px", objectFit: "contain" }}
            alt="logo"
          />
        </a>
      )}
      {!global.userInfo.logoUrl && (
        <a href="/landing">
          <img
            src="iota-logo.png"
            style={{ width: "208px", height: "44px", objectFit: "contain" }}
            alt="logo"
          />
        </a>
      )}
    </Box>
  );
}
