const isObject = (value) => {
  return !!(value && typeof value === "object" && !Array.isArray(value));
};

export default function findNestedObject(
  object = {},
  keyToMatch = "",
  valueToMatch = "",
  includes = false
) {
  if (isObject(object)) {
    const entries = Object.entries(object);

    for (let i = 0; i < entries.length; i += 1) {
      const [objectKey, objectValue] = entries[i];

      if (!includes) {
        if (objectKey === keyToMatch && objectValue === valueToMatch) {
          return object;
        }
      } else {
        if (objectKey === keyToMatch && objectValue.includes(valueToMatch)) {
          return object;
        }
      }
      if (isObject(objectValue)) {
        const child = findNestedObject(
          objectValue,
          keyToMatch,
          valueToMatch,
          includes
        );

        if (child !== null) {
          return child;
        }
      }
    }
  }

  return null;
}

export function findNestedObjectParents(
  object = {},
  keyToMatch = "",
  valueToMatch = ""
) {
  // split on - of key to find parent
  const id = valueToMatch.split("-");
  var depth = id[1];
  const url = id[0];
  var newValueToMatch;
  var result = [];
  while (depth > -1) {
    newValueToMatch = `${url}-${depth--}`;
    const foundNode = findNestedObject(
      object,
      keyToMatch,
      newValueToMatch,
      true
    ); // true for includes
    result.push(foundNode);
    console.log(foundNode);
  }
  return result;
}
