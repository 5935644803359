import React, { useContext, useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import GlobalStateContext from "../../globalstate/globalStateContext";
import { Copyright } from "../../components/copyright";
import SendToApi from "../../api/sendtoapi";
import CTForm from "../../components/forms/ctform";
import layout from "./layout.json";
import { purple } from "@mui/material/colors";
import { useState } from "react";
import jwt_decode from "jwt-decode";
import { Navigate } from "react-router-dom";

export default function Forgot() {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const global = useContext(GlobalStateContext);

  async function handleForgot(values) {
    setError(false);
    console.log(values);
    const response = await SendToApi("users/forgotpassword", values);
    if (!response) {
      global.setStateUplift((prevState) => ({
        ...prevState,
        error: "network error",
      }));
      setError(
        "IOTAML server problem - please try later - we will be looking into it"
      );
      return false;
    }

    switch (response.status) {
      case 200:
        setSuccess(true);
        break;
      case 201:
        break;
      case 401:
        global.setStateUplift((prevState) => ({
          ...prevState,
          error: response.statusText,
        }));
        setError("Email failed");
        break;
      default:
        setError(
          "IOTAML server problem - please try later - we will be looking into it"
        );
        break;
    }
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "#ccc",
      }}
    >
      <Box
        mt={5}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Paper>
          <Box
            p={5}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ bgcolor: purple[500] }}>
              <LockIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Forgot Password
            </Typography>
            {!success && (
              <>
                <CTForm
                  submitText="Forgot Password"
                  handleSubmit={handleForgot}
                  layout={layout.forgot}
                />

                {error && (
                  <Box mt={5}>
                    <Alert severity="error">
                      <AlertTitle>Error</AlertTitle>
                      {error}.
                    </Alert>
                  </Box>
                )}
              </>
            )}
            {success && (
              <Box mt={5}>
                <Typography component="h3" align="center">
                  We have sent you an email with a password reset link in it.
                </Typography>
              </Box>
            )}
            <Box mt={5}>
              <img
                src="iota-logo.png"
                style={{ width: "150px", height: "100%", objectFit: "contain" }}
                alt="logo"
              />
            </Box>

            <Box>
              <Copyright />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
