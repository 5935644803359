import { useContext, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import CTDAppBar from "../../appbar/appbar";
import CTDLogo from "../../logo/logo";

import Drawer from "@mui/material/Drawer";

import Menu from "../../menu/menu";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";
import GlobalStateContext from "../../globalstate/globalStateContext";

import { Typography } from "@mui/material";

// content
import Dashboard from "../../components/dashboard/dashboard";
import ManageAccount from "../../components/manageaccounts/manageaccount";
import ProtectedRoute from "../../auth/protectedroute";
import Selector from "./selector";
import Labeller from "./labeller";
// Icons
// Icons

import LocalOfferIcon from "@mui/icons-material/LocalOffer";

import IconDashboard from "@mui/icons-material/Dashboard";

import BallotIcon from "@mui/icons-material/Ballot";
import ArticleIcon from "@mui/icons-material/Article";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Reports from "../../components/reports/reports";

const menuItems = [
  {
    name: "Dashboard",
    link: "dashboard",
    Icon: IconDashboard,
  },
  {
    name: "Selector",
    Icon: BallotIcon,
    link: "selector",
  },
  {
    name: "Labeller",
    link: "labeller",
    Icon: LocalOfferIcon,
  },
  {
    name: "Reports",
    link: "reports",
    Icon: ArticleIcon,
  },

  {
    name: "divider",
  },
  {
    name: "Account",
    link: "account",
    Icon: ManageAccountsIcon,
  },
  /*{
    name: "Nested Pages",
    Icon: IconLibraryBooks,
    items: [
      {
        name: "Level 1",
        link: "/nested1",
      },
      {
        name: "Level 2",
        items: [
          {
            name: "Level 3",
          },
          {
            name: "Level 3",
          },
        ],
      },
    ],
  },*/
];

export default function Predict() {
  const theme = useTheme();
  const global = useContext(GlobalStateContext);

  useEffect(() => {
    global.setState((prevState) => ({
      ...prevState,
      application: "predict",
    }));
  }, []);

  return (
    <>
      <CTDAppBar />
      <CTDLogo />
      <Drawer
        sx={{
          width: "240px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "240px",
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Menu menuItems={menuItems} />
      </Drawer>

      <Box
        sx={{
          marginTop: "64px",
          marginLeft: "240px",
          padding: theme.spacing(2),
        }}
      >
        <Box sx={{ marginTop: "2em" }}>
          <Routes>
            <Route
              path="/"
              exact
              element={
                <ProtectedRoute>
                  {global.state.wssConnected && <Dashboard />}
                  {!global.state.wssConnected && (
                    <Box>
                      <Typography>
                        No real time service found - please contact IOTAML
                      </Typography>
                    </Box>
                  )}
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard"
              exact
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="selector"
              exact
              element={
                <ProtectedRoute>
                  <Selector />
                </ProtectedRoute>
              }
            />
            <Route
              path="labeller"
              exact
              element={
                <ProtectedRoute>
                  <Labeller />
                </ProtectedRoute>
              }
            />
            <Route
              path="reports"
              exact
              element={
                <ProtectedRoute>
                  <Reports />
                </ProtectedRoute>
              }
            />

            <Route
              path="account"
              exact
              element={
                <ProtectedRoute>
                  <ManageAccount />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Box>
      </Box>
    </>
  );
}
